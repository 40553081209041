import { DEFAULT_LIMIT, ListResponse, QueryParams, SuccessResponce } from 'utils/constants';
import { api } from './api';
import { GetNearestTechnicianResponseDTO, JobResponseDTO } from 'pages/jobs/jobSlice';

export type JobDTO = {
  id?: string;
  technicianID: string;
  customerID: string;
  serviceCategoryID: number;
  lat: number;
  long: number;
  date: Date;
  customerVehicleID: string;
  area?: string;
  address?: string;
  jobType: 1 | 2; // NORMAL = 1, EMERGENCY = 2
  vehicleRegistrationNumber: string;
  vehicleKilometer: number;
  startTime: Date;
  navigation: 'Y' | 'N';
  jobComplaint: string;
};

export type QuickJobInput = {
  customerName: string;
  customerMobile: string;
  vehicle: number;
  regNumber: string;
  serviceCategory: number;
  provider: string;
  complaint: string;
  assignedAgent: string;
  location?: string;
  lat: number;
  long: number;
};

type ListJobsResponse = ListResponse<JobResponseDTO>;

export const jobApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listJobs: builder.query<ListJobsResponse, QueryParams>({
      query: (params) => {
        const {
          limit = DEFAULT_LIMIT,
          offset = 0,
          technicianID,
          customerID,
          betweenDates,
          statusID,
          jobID,
          area,
          serviceCategoryID,
          jobType, //Normal = 1, Emergency = 2
          districtID,
          stateID,
          followupStatusID,
          customerMobile,
          jobSource,
          jobProviders,
          jobSourceReference,
          agentID,
          generalSearch
        } = params;
        let queryUrl = `/admin/job?limit=${limit}&offset=${offset}`;

        if (typeof technicianID === 'string') {
          queryUrl += `&technicianID=${technicianID}`;
        }

        if (typeof districtID === 'number') {
          queryUrl += `&districtID=${districtID}`;
        }

        if (typeof stateID === 'number') {
          queryUrl += `&stateID=${stateID}`;
        }

        if (typeof followupStatusID === 'number') {
          queryUrl += `&followupStatusID=${followupStatusID}`;
        }

        if (typeof customerMobile === 'string') {
          queryUrl += `&customerMobile=${customerMobile}`;
        }

        if (typeof customerID === 'string') {
          queryUrl += `&customerID=${customerID}`;
        }

        if (typeof area === 'string') {
          queryUrl += `&area=${area}`;
        }

        if (typeof statusID === 'number') {
          queryUrl += `&statusID=${statusID}`;
        }

        if (typeof jobID === 'string') {
          queryUrl += `&jobID=${jobID}`;
        }

        if (typeof serviceCategoryID === 'number') {
          queryUrl += `&serviceCategoryID=${serviceCategoryID}`;
        }

        if (typeof jobType === 'number') {
          queryUrl += `&jobType=${jobType}`;
        }

        if (typeof betweenDates === 'object') {
          queryUrl += `&${betweenDates.map((date: any) => `betweenDates=${date}`).join('&')}`;
        }

        if (typeof jobSource === 'string') {
          queryUrl += `&jobSource=${jobSource}`;
        }

        if (typeof jobProviders === 'string') {
          queryUrl += `&jobProvider=${jobProviders}`;
        }

        if (typeof jobSourceReference === 'string') {
          queryUrl += `&jobSourceReference=${jobSourceReference}`;
        }

        if (typeof agentID === 'string') {
          queryUrl += `&agentID=${agentID}`;
        }

        if (typeof generalSearch === 'string') {
          queryUrl += `&generalSearch=${generalSearch}`;
        }

        return queryUrl;
      },
      transformResponse: (res: ListResponse<JobResponseDTO>) => {
        let items = res?.items?.map((item: JobResponseDTO) => {
          return { ...item, id: item['jobID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: ListResponse<JobResponseDTO> = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(({ id }: { id: number }) => ({ type: 'Job', id: id } as const)) ?? [];
        return [...items, { type: 'Job' as const, id: 'LIST' }];
      }
    }),
    createJob: builder.mutation<SuccessResponce, Partial<JobDTO>>({
      query(body) {
        return {
          url: `/admin/job`,
          method: 'POST',
          body
        };
      },
      extraOptions: {
        maxRetries: 0
      },
      invalidatesTags: ['Job']
    }),
    getJobAvailableTechnicians: builder.query<GetNearestTechnicianResponseDTO[], any>({
      query: (params) => {
        const { lat, long, serviceCategoryID, filter, jobTypeID, distance, experimental } = params;
        let queryUrl = `/admin/job/available-technicians?lat=${lat}&long=${long}&serviceCategoryID=${serviceCategoryID}`;

        if (typeof filter === 'string') {
          queryUrl += `&filter=${filter}`;
        }

        if (typeof jobTypeID === 'number') {
          queryUrl += `&jobTypeID=${jobTypeID}`;
        }

        if (typeof distance === 'string') {
          queryUrl += `&distance=${distance}`;
        }

        if (typeof experimental === 'boolean') {
          queryUrl += `&experimental=${experimental}`;
        }

        return queryUrl;
      },
      providesTags: ['Technician']
    }),
    getJobDetails: builder.query({
      query: (params) => {
        const { jobId } = params;
        const queryUrl = `/admin/job/${jobId}/details`;
        return queryUrl;
      },
      providesTags: ['Job']
    }),
    updateJob: builder.mutation({
      query(body) {
        return {
          url: `/admin/job/${body.id}`,
          method: 'PUT',
          body,
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['Job']
    }),
    createFollowup: builder.mutation({
      query(body) {
        return {
          url: `/admin/job/${body.jobId}/follow-up`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['Job']
    }),
    selfAssingJob: builder.mutation({
      query(body: { jobID?: number; agenID?: string }) {
        return {
          url: `/admin/followup/self-assign`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    generatePaymentLink: builder.mutation({
      query: (body) => ({
        url: `/admin/job/customer-payment-link/${body}`,
        method: 'POST'
      }),
      // invalidatesTags: (result, error, { jobId }) => [{ type: 'Job', id: jobId }],
      invalidatesTags: ['Job']
    }),
    listJobTypes: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT } = params;
        let queryUrl = `/job/types?limit=${limit}`;

        return queryUrl;
      },
      providesTags: (result = {}) => {
        const items =
          result?.map(
            ({ jobTypeID }: { jobTypeID: string }) => ({ type: 'JobTypes', jobTypeID } as const)
          ) ?? [];
        return [...items, { type: 'JobTypes' as const, jobTypeID: 'LIST' }];
      }
    }),
    updateJobTypeAgreement: builder.mutation({
      query(body) {
        const { jobTypeID, agreementID } = body;
        return {
          url: `/job/type/${jobTypeID}/agreement`,
          method: 'PUT',
          body: { agreementID }
        };
      },
      invalidatesTags: ['JobTypes']
    }),
    quickJob: builder.mutation<SuccessResponce, Partial<QuickJobInput>>({
      query(body) {
        return {
          url: `/admin/quick-job`,
          method: 'POST',
          body
        };
      },
      extraOptions: {
        maxRetries: 0
      },
      invalidatesTags: ['Job']
    })
  })
});

export const {
  useListJobsQuery,
  useCreateJobMutation,
  useGetJobAvailableTechniciansQuery,
  useGetJobDetailsQuery,
  useLazyGetJobDetailsQuery,
  useUpdateJobMutation,
  useCreateFollowupMutation,
  useSelfAssingJobMutation,
  useGeneratePaymentLinkMutation,
  useListJobTypesQuery,
  useUpdateJobTypeAgreementMutation,
  useQuickJobMutation
} = jobApi;
