/* eslint-disable import/no-anonymous-default-export */
import { Icon } from '@iconify/react';
import { Chip } from '@mui/material';

enum DEPARTMENT {
  CALL_CENTER = 'CALL_CENTER',
}

export enum ROLE_CODES {
  CALL_CENTER_SUPERVISOR = 3002,
  CALL_CENTER_USER = 3003,
}

const roleCodes: any = {
  3003: {
    department: DEPARTMENT.CALL_CENTER,
    canAssign: true,
    canTake: true,
  },
  3002: {
    department: DEPARTMENT.CALL_CENTER,
    canAssign: true,
    canTake: true,
  },
};

export function getTypeColor(type: string) {
  switch (type) {
    case 'CARRY_FORWARD':
      return 'orange';
    case 'CURRENT_JOB':
      return 'success';
    case 'PREVIOUS_FOLLOWUP':
      return 'primary';
  }
}

export function getTypeIcon(type: string) {
  switch (type) {
    case 'CARRY_FORWARD':
      return (
        <Icon
          icon='fluent:fast-forward-24-regular'
          color='orange'
          width={20}
          height={20}
        />
      );
    case 'CURRENT_JOB':
      return (
        <Icon
          icon='heroicons-outline:inbox-in'
          color='success'
          width={20}
          height={20}
        />
      );
    case 'PREVIOUS_FOLLOWUP':
      return <Icon icon='f7:backward' color='primary' width={20} height={20} />;
  }
}

export function getStatusColor(status: string) {
  switch (status) {
    case 'NEW_JOB':
      return 'primary';
    case 'CONFIRMING_REQUIREMENTS':
      return 'warning';
    case 'SEARCHING_FOR_TECHNICIAN':
      return 'warning';
    case 'TECHNICIAN_ASSIGNED':
      return 'success';
    case 'JOB_IN_PROGRESS':
      return 'info';
    case 'JOB_PENDING':
      return 'warning';
    case 'JOB_CANCELLED_BY_CUSTOMER':
      return 'error';
    case 'JOB_CANCELLED_BY_AUTOAID':
      return 'error';
    case 'JOB_FAILED':
      return 'error';
    case 'MULTIPLE_JOB':
      return 'error';
    case 'TEST_DEMO':
      return 'default';
    case 'JOB_COMPLETED_PENDING_FOR_PAYMENT':
      return 'info';
    case 'COLLECTION_PENDING_FROM_TECH':
      return 'info';
    case 'PAYMENT_FAILED':
      return 'error';
    case 'PAYMENT_COMPLETE':
      return 'info';
    case 'TEST_JOB_FROM_CUSTOMER':
      return 'info';
  }
}

export function getStatusIcon(status: string) {
  switch (status) {
    case 'NEW_JOB':
      return <Icon icon='lets-icons:progress' width='1.3rem' height='1.3rem' />;
    case 'CONFIRMING_REQUIREMENTS':
      return (
        <Icon
          icon='material-symbols:collections-bookmark-outline-sharp'
          width='1.3rem'
          height='1.3rem'
        />
      );
    case 'SEARCHING_FOR_TECHNICIAN':
      return (
        <Icon icon='mingcute:user-search-line' width='1.3rem' height='1.3rem' />
      );
    case 'TECHNICIAN_ASSIGNED':
      return (
        <Icon icon='clarity:assign-user-line' width='1.3rem' height='1.3rem' />
      );
    case 'JOB_IN_PROGRESS':
      return <Icon icon='mdi:progress-wrench' width='1.3rem' height='1.3rem' />;
    case 'JOB_PENDING':
      return (
        <Icon
          icon='fluent:flag-clock-28-regular'
          width='1.3rem'
          height='1.3rem'
        />
      );
    case 'JOB_CANCELLED_BY_CUSTOMER':
      return (
        <Icon
          icon='mdi:account-cancel-outline'
          width='1.3rem'
          height='1.3rem'
        />
      );
    case 'JOB_CANCELLED_BY_AUTOAID':
      return <Icon icon='mdi:cancel' width='1.3rem' height='1.3rem' />;
    case 'JOB_FAILED':
      return (
        <Icon
          icon='icon-park-outline:folder-failed'
          width='1.3rem'
          height='1.3rem'
        />
      );
    case 'MULTIPLE_JOB':
      return <Icon icon='mdi:car-multiple' width='1.3rem' height='1.3rem' />;
    case 'TEST_DEMO':
      return <Icon icon='grommet-icons:test' width='1.3rem' height='1.3rem' />;
    case 'JOB_COMPLETED_PENDING_FOR_PAYMENT':
      return <Icon icon='mdi:payment-clock' width='1.3rem' height='1.3rem' />;
    case 'COLLECTION_PENDING_FROM_TECH':
      return (
        <Icon icon='mdi:receipt-text-pending' width='1.3rem' height='1.3rem' />
      );
    case 'PAYMENT_FAILED':
      return <Icon icon='mdi:cash-remove' width='1.3rem' height='1.3rem' />;
    case 'PAYMENT_COMPLETE':
      return <Icon icon='mdi:cash-check' width='1.3rem' height='1.3rem' />;
    case 'TEST_JOB_FROM_CUSTOMER':
      return (
        <Icon
          icon='material-symbols:person-play-outline'
          width='1.3rem'
          height='1.3rem'
        />
      );
    default:
      return (
        <Icon
          icon='icon-park-outline:folder-failed'
          width='1.3rem'
          height='1.3rem'
        />
      );
  }
}
export function getStatusChip(status: string) {
  switch (status) {
    case 'NEW_JOB':
      return (
        <Chip
          variant='outlined'
          color='primary'
          icon={getStatusIcon(status)}
          label='New Job'
        />
      );
    case 'CONFIRMING_REQUIREMENTS':
      return (
        <Chip
          variant='outlined'
          color='warning'
          icon={getStatusIcon(status)}
          label='Confirming Requirements'
        />
      );
    case 'SEARCHING_FOR_TECHNICIAN':
      return (
        <Chip
          variant='outlined'
          color='warning'
          icon={getStatusIcon(status)}
          label='Searching For Technician'
        />
      );
    case 'TECHNICIAN_ASSIGNED':
      return (
        <Chip
          variant='outlined'
          color='success'
          icon={getStatusIcon(status)}
          label='Technician Assigned'
        />
      );
    case 'JOB_IN_PROGRESS':
      return (
        <Chip
          variant='outlined'
          color='info'
          icon={getStatusIcon(status)}
          label='Job In Progress'
        />
      );
    case 'JOB_PENDING':
      return (
        <Chip
          variant='outlined'
          color='warning'
          icon={getStatusIcon(status)}
          label='Job Pending'
        />
      );
    case 'JOB_CANCELLED_BY_CUSTOMER':
      return (
        <Chip
          variant='outlined'
          color='error'
          icon={getStatusIcon(status)}
          label='Job Cancelled By Customer'
        />
      );
    case 'JOB_CANCELLED_BY_AUTOAID':
      return (
        <Chip
          variant='outlined'
          color='error'
          icon={getStatusIcon(status)}
          label='Job Cancelled By AutoAid'
        />
      );
    case 'JOB_FAILED':
      return (
        <Chip
          variant='outlined'
          color='error'
          icon={getStatusIcon(status)}
          label='Job Failed'
        />
      );
    case 'MULTIPLE_JOB':
      return (
        <Chip
          variant='outlined'
          color='error'
          icon={getStatusIcon(status)}
          label='Multiple Job'
        />
      );
    case 'TEST_DEMO':
      return (
        <Chip
          variant='outlined'
          color='default'
          icon={getStatusIcon(status)}
          label='Test Demo'
        />
      );
    case 'JOB_COMPLETED_PENDING_FOR_PAYMENT':
      return (
        <Chip
          variant='outlined'
          color='info'
          icon={getStatusIcon(status)}
          label='Job Completed Pending For Payment'
        />
      );
    case 'COLLECTION_PENDING_FROM_TECH':
      return (
        <Chip
          variant='outlined'
          color='info'
          icon={getStatusIcon(status)}
          label='Collection Pending From Tech'
        />
      );
    case 'PAYMENT_FAILED':
      return (
        <Chip
          variant='outlined'
          color='error'
          icon={getStatusIcon(status)}
          label='Payment Failed'
        />
      );
    case 'PAYMENT_COMPLETE':
      return (
        <Chip
          variant='outlined'
          color='success'
          icon={getStatusIcon(status)}
          label='Payment Complete'
        />
      );
    case 'TEST_JOB_FROM_CUSTOMER':
      return (
        <Chip
          variant='outlined'
          color='default'
          icon={getStatusIcon(status)}
          label='Test Job From Customer'
        />
      );
    default:
      return (
        <Chip
          variant='outlined'
          color='primary'
          icon={getStatusIcon(status)}
          label='New Job'
        />
      );
  }
}

export function getStatusName(status: number) {
  switch (status) {
    case 0:
      return 'NEW_JOB';
    case 1:
      return 'CONFIRMING_REQUIREMENTS';
    case 2:
      return 'SEARCHING_FOR_TECHNICIAN';
    case 3:
      return 'TECHNICIAN_ASSIGNED';
    case 4:
      return 'JOB_IN_PROGRESS';
    case 5:
      return 'JOB_PENDING';
    case 6:
      return 'JOB_CANCELLED_BY_CUSTOMER';
    case 7:
      return 'JOB_CANCELLED_BY_AUTOAID';
    case 8:
      return 'JOB_FAILED';
    case 9:
      return 'MULTIPLE_JOB';
    case 10:
      return 'TEST_DEMO';
    case 11:
      return 'JOB_COMPLETED_PENDING_FOR_PAYMENT';
    case 12:
      return 'COLLECTION_PENDING_FROM_TECH';
    case 13:
      return 'PAYMENT_FAILED';
    case 14:
      return 'PAYMENT_COMPLETE';
    case 15:
      return 'TEST_JOB_FROM_CUSTOMER';
    default:
      return 'NEW_JOB';
  }
}

export function getStatusID(status: string) {
  switch (status) {
    case 'NEW_JOB':
      return 0;
    case 'CONFIRMING_REQUIREMENTS':
      return 1;
    case 'SEARCHING_FOR_TECHNICIAN':
      return 2;
    case 'TECHNICIAN_ASSIGNED':
      return 3;
    case 'JOB_IN_PROGRESS':
      return 4;
    case 'JOB_PENDING':
      return 5;
    case 'JOB_CANCELLED_BY_CUSTOMER':
      return 6;
    case 'JOB_CANCELLED_BY_AUTOAID':
      return 7;
    case 'JOB_FAILED':
      return 8;
    case 'MULTIPLE_JOB':
      return 9;
    case 'TEST_DEMO':
      return 10;
    case 'JOB_COMPLETED_PENDING_FOR_PAYMENT':
      return 11;
    case 'COLLECTION_PENDING_FROM_TECH':
      return 12;
    case 'PAYMENT_FAILED':
      return 13;
    case 'PAYMENT_COMPLETE':
      return 14;
    case 'TEST_JOB_FROM_CUSTOMER':
      return 15;
    default:
      return 0;
  }
}

export function canTakeJob(status: string) {
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');
  const roleCode: any = user?.roleCode;
  if (typeof roleCode != 'number') return;
  if (!roleCodes?.[roleCode]?.canTake) {
    return true;
  }
  switch (status) {
    case 'NEW_JOB':
      return true;
    default:
      return false;
  }
}

export function isCallCenterUser() {
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');
  const roleCode: any = user?.roleCode;
  if (typeof roleCode != 'number') return;
  if (roleCodes?.[roleCode]?.department === DEPARTMENT.CALL_CENTER) {
    return true;
  }
  return false;
}

export function isCallCenterSupervisor() {
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');
  const roleCode: any = user?.roleCode;
  if (typeof roleCode != 'number') return;
  if (roleCode === ROLE_CODES.CALL_CENTER_SUPERVISOR) {
    return true;
  }
  return false;
}

export function getCurrentUserID() {
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');
  return user?.adminID;
}

export function getFollowupStatusList(forSelect = false): any {
  const followupStatus: any = {
    NEW_JOB: 1,
    CONFIRMING_REQUIREMENTS: 2,
    SEARCHING_FOR_TECHNICIAN: 3,
    TECHNICIAN_ASSIGNED: 4,
    JOB_IN_PROGRESS: 5,
    JOB_PENDING: 6,
    JOB_CANCELLED_BY_CUSTOMER: 7,
    JOB_CANCELLED_BY_AUTOAID: 8,
    JOB_FAILED: 9,
    MULTIPLE_JOB: 10,
    TEST_DEMO: 11,
    JOB_COMPLETED_PENDING_FOR_PAYMENT: 12,
    COLLECTION_PENDING_FROM_TECH: 13,
    PAYMENT_FAILED: 14,
    PAYMENT_COMPLETE: 15,
    TEST_JOB_FROM_CUSTOMER: 16,
  };

  if (forSelect) {
    return Object.keys(followupStatus).map((key: string) => {
      return {
        value: followupStatus[key],
        label: key.replace(/_/gi, ' ').toLowerCase(),
      };
    });
  }
  return Object.keys(followupStatus);
}

export function getFollowupTypeList() {
  return ['CARRY_FORWARD', 'CURRENT_JOB', 'PREVIOUS_FOLLOWUP'];
}

export default {
  getStatusIcon,
  getStatusChip,
  getStatusName,
  getStatusID,
  canTakeJob,
  isCallCenterUser,
  getStatusColor,
  getFollowupTypeList,
  getFollowupStatusList,
  getTypeColor,
  getTypeIcon,
};
