import { CircularProgress, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactNode } from 'react';
import { Controller, Control } from 'react-hook-form';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
type InputFieldType = Partial<TextFieldProps> & {
  control: Control<any>;
  defaultValue?: unknown;
  loading?: boolean;
  children?: ReactNode;
};

const InputField = ({
  name = '',
  defaultValue = '',
  control,
  label,
  variant = 'outlined',
  size = 'medium',
  loading = false,
  children,
  ...rest
}: InputFieldType) => {
  if (rest.type === 'date-select') {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={label}
              onChange={onChange}
              autoFocus={false}
              sx={{ width: '100%' }}
              value={moment(value || '')}
            />
          </LocalizationProvider>
          // <TextField
          //   autoComplete="off"
          //   fullWidth
          //   onChange={onChange}
          //   value={value || ''}
          //   id={name}
          //   label={label}
          //   variant={variant}
          //   size={size}
          //   {...rest}>
          //   {loading && rest?.select ? (
          //     <MenuItem disabled>
          //       <CircularProgress size={20} />
          //       <span style={{ marginLeft: 10 }}>Loading...</span>
          //     </MenuItem>
          //   ) : (
          //     children
          //   )}
          // </TextField>
        )}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          autoComplete="off"
          fullWidth
          onChange={onChange}
          value={value || ''}
          id={name}
          label={label}
          variant={variant}
          size={size}
          {...rest}>
          {loading && rest?.select ? (
            <MenuItem disabled>
              <CircularProgress size={20} />
              <span style={{ marginLeft: 10 }}>Loading...</span>
            </MenuItem>
          ) : (
            children
          )}
        </TextField>
      )}
    />
  );
};

export default InputField;
