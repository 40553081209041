import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListResponse } from 'utils/constants';

export type UserResponseDTO = {
  adminID: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  username: string;
  createdAt: string;
};

export type UserRolesDto = {
  roleID: number;
  roleName: string;
};

export type UserDetailsFeaturePermission = {
  permissionID: number;
  permissionName: string;
  hasPermission: 'Y' | 'N';
};
export type UserDetailsFeatures = {
  featureID: number;
  featureName: string;
  permissions: UserDetailsFeaturePermission[];
  permissionID: number;
  permissionName: string;
  hasPermission: 'Y' | 'N';
};

export type UserLanguage = {
  languageID: number;
  languageName: string;
  efficiency: 'Y' | 'N';
};

export type UserDetailsDto = {
  adminID: string;
  firstName: string;
  lastName: string;
  email?: string;
  nullable: true;
  role: string;
  roleID: number;
  username: string;
  adminPhone: string;
  createdDate: string;
  createdBy: string;
  features: UserDetailsFeatures[];
  adminEnabled?: 'Y' | 'N';
  disabledBy: string;
  disabledDate: string;
  disabledReason: string;
  state?: {
    stateID: number;
    stateName: string;
  };
  district?: {
    districtID: number;
    districtName: string;
  };
  agentLanguages: UserLanguage[];
};

type UserState = ListResponse<UserResponseDTO> & { roles: any[] };

const initialState: UserState = { total: 0, items: [], roles: [] };

const staffSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStaffData: (
      state,
      action: PayloadAction<{
        total: number;
        items: UserResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
    setAdminRoles: (
      state,
      action: PayloadAction<{
        roles: any[];
      }>
    ) => {
      const { roles } = action.payload;
      state.roles = roles;
    }
  }
});

export const { setStaffData, setAdminRoles } = staffSlice.actions;

export const selectAllStaff = (state: RootState) => state.staff.items;
export const selectAllAdminRoles = (state: RootState) => state.staff.roles;

export default staffSlice.reducer;
