import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type GetAllGarageResponseDTOBusinessHours = {
  businessHoursID: number;
  garageID: number;
  businessDay:
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY';
  businessDayStart: string;
  businessDayEnd: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string | null;
  updatedBy: string | null;
};

export type GetAllGarageResponseDTO = {
  garageID: number;
  garageName: string;
  garageEnabled: 'Y' | 'N';
  garageCategory: 'A' | 'B' | 'C' | 'D';
  garageFacilities: string | null;
  garageType: 'FREELANCE' | 'PART_TIME' | 'GARAGE_OWNER';
  doorToDoor: 'Y' | 'N';
  garageDistance: number;
  districtID: number;
  stateID: number;
  garageAlwaysOpen: 'Y' | 'N';
  createdDate: string;
  createdBy: string;
  updatedDate: string | null;
  updatedBy: string;
  businessHours: GetAllGarageResponseDTOBusinessHours;
};

type GarageState = {
  total: number | null;
  items: GetAllGarageResponseDTO[];
};

const initialState: GarageState = { total: null, items: [] };

const garageSlice = createSlice({
  name: 'garage',
  initialState,
  reducers: {
    setGarageData: (
      state,
      action: PayloadAction<{ total: number; items: GetAllGarageResponseDTO[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setGarageData } = garageSlice.actions;

export const selectAllGarages = (state: RootState) => state.garage.items;

export default garageSlice.reducer;
