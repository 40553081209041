import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type CustomerResponseDTO = {
  name: string;
  customerID: string;
  email: string;
  phone: string;
  numberOfJobs: number;
  pendingJobs: number;
  approvedJobs: number;
  completedJobs: number;
  createdDate: Date;
};

type CustomerState = {
  total: number | null;
  items: CustomerResponseDTO[];
};
const initialState: CustomerState = { total: null, items: [] };

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerData: (
      state,
      action: PayloadAction<{
        total: number;
        items: CustomerResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setCustomerData } = customerSlice.actions;

export const selectAllCustomer = (state: RootState) => state.customer.items;

export default customerSlice.reducer;
