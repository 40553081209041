import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';

export const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listDashboardData: builder.query({
      query: () => `/admin/dashboard`
    }),
    listJobQue: builder.query({
      providesTags: ['JobQue'],
      keepUnusedDataFor: 0,
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, status, followupStatus, createdDate } = params;
        let queryUrl = `/admin/followup/que?limit=${limit}&offset=${offset}`;

        if (typeof status === 'number') {
          queryUrl += `&status=${status}`;
        }

        if (typeof followupStatus === 'number') {
          queryUrl += `&followupStatus=${followupStatus}`;
        }

        if (typeof createdDate === 'string') {
          queryUrl += `&createdDate=${createdDate}`;
        }

        return queryUrl;
      }
    }),
    listAgentJobs: builder.query({
      providesTags: ['JobQue'],
      keepUnusedDataFor: 0,
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, status, followupStatus, createdDate } = params;

        let queryUrl = `/admin/followup/agent-jobs?limit=${limit}&offset=${offset}`;

        if (typeof status === 'number') {
          queryUrl += `&status=${status}`;
        }

        if (typeof followupStatus === 'number') {
          queryUrl += `&followupStatus=${followupStatus}`;
        }

        if (typeof createdDate === 'string') {
          queryUrl += `&createdDate=${createdDate}`;
        }

        return queryUrl;
      }
    })
  })
});

export const {
  useListDashboardDataQuery,
  useLazyListJobQueQuery,
  useListJobQueQuery,
  useLazyListAgentJobsQuery,
  useListAgentJobsQuery
} = dashboardApi;
