import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

// type GarageState = {
//   total: number | null;
//   items: GetAllGarageResponseDTO[];
// };

// const initialState: GarageState = { total: null, items: [] };

const initialState: any = {
  // earningsReport: null,
  // selectedEarnings: null,
  // generalReport: null,
  // kpiReport: null,
  paymentAccounts: [],
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentAccounts: (
      state,
      action: PayloadAction<{ generalReport: any }>
    ) => {
      state.paymentAccounts = action.payload;
    },
  },
});

export const { setPaymentAccounts } = paymentSlice.actions;

export const selectPaymentAccounts = (state: RootState) =>
  state.payment.paymentAccounts;

export default paymentSlice.reducer;
