import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type JobResponseDTO = {
  id: number;
  jobID: number;
  technicianName: string;
  customerName: string;
  jobDate: Date;
  serviceCategoryName: string;
  jobType: number;
  jobStatus: number;
  jobStatusName: string;
};

type ServiceState = {
  total: number | null;
  items: JobResponseDTO[];
  selectedJob: {};
};

type GetNearestTechnicianVehicle = {
  name: string;
};

export type GetNearestTechnicianResponseDTO = {
  technicianID: string;
  technicianMinimumCharge?: number;
  techID: string;
  technicianName: string;
  documentsVerified: 'Y' | 'N';
  technicianImage?: string;
  technicianPhone?: string;
  distance: number;
  rating?: number;
  mediaUrl?: string;
  vehicles?: GetNearestTechnicianVehicle[];
  lat: number;
  long: number;
  technicianExperience?: string;
  serviceCategoryName: string;
  agreementID?: number;
  dayPrice?: number;
  nightPrice?: number;
  maxKmTravel?: string;
  pricePerKm: string;
  timeSlot?: string;
  openNow?: string;
  totalCompletedJobs?: number;
};

const initialState: ServiceState = {
  total: null,
  items: [],
  selectedJob: {}
};

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setJobData: (
      state,
      action: PayloadAction<{
        total: number;
        items: JobResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
    setSelectedJobData: (
      state,
      action: PayloadAction<{
        selectedJob: any;
      }>
    ) => {
      const { selectedJob } = action.payload;
      state.selectedJob = selectedJob;
    }
  }
});

export const { setJobData, setSelectedJobData } = jobSlice.actions;

export const selectAllJob = (state: RootState) => state.job.items;
export const selectedJob = (state: RootState) => state.job.selectedJob;

export default jobSlice.reducer;
