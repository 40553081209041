import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IStateResponseItem } from 'app/services/state';

import { RootState } from 'app/store';
import { ListResponse } from 'utils/constants';

type StateItemState = ListResponse<IStateResponseItem>;

const initialState: StateItemState = { total: 0, items: [] };

const stateSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setStateData: (
      state,
      action: PayloadAction<{
        total: number;
        items: IStateResponseItem[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setStateData } = stateSlice.actions;

export const selectAllState = (state: RootState) => state.state.items;

export default stateSlice.reducer;
