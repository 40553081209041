import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ILanguageItem } from 'app/services/language';
import { RootState } from 'app/store';
import { ListResponse } from 'utils/constants';

type LanguageState = ListResponse<ILanguageItem>;

const initialState: LanguageState = { total: 0, items: [] };

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguageData: (
      state,
      action: PayloadAction<{
        total: number;
        items: ILanguageItem[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setLanguageData } = languageSlice.actions;

export const selectAllLanguage = (state: RootState) => state.language.items;

export default languageSlice.reducer;
