import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import debounce from '@mui/material/utils/debounce';
import { MenuItem } from '@mui/material';
import moment from 'moment';

import { FiltersProps, FiltersPropsItem } from './FilterButton';

const ITEM_WIDTH = 200;
const DateRange = ({
  objKey,
  handleDateBetweenChange,
  filter
}: {
  objKey: string;
  handleDateBetweenChange: (data: { key: string; value: string[] }) => any;
  filter: FiltersPropsItem;
}) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [arrayDate, setArrayDate] = useState<string[] | any[]>([]);

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    const startOfDay = moment(date).startOf('day').toISOString();
    setStartDate(startOfDay);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    const endOfDay = moment(date).endOf('day').toISOString();
    setEndDate(endOfDay);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setArrayDate([moment(startDate).toISOString(), moment(endDate).toISOString()]);
    }

    return () => {};
  }, [endDate, startDate]);

  useEffect(() => {
    if (arrayDate.length === 2) {
      debounce(handleDateBetweenChange({ key: objKey, value: arrayDate }), 500);
    }

    return () => {};
  }, [arrayDate, handleDateBetweenChange, objKey]);

  return (
    <Box display="flex" gap={2}>
      <TextField
        name={`${objKey}-start`}
        size="small"
        type="date"
        label="Start Date"
        InputLabelProps={{ shrink: true }}
        onChange={handleStartDateChange}
        defaultValue={
          filter?.value?.length > 0 ? moment(new Date(filter?.value[0])).format('YYYY-MM-DD') : null
        }
        sx={{
          width: ITEM_WIDTH + 20,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#676767'
            }
          }
        }}
      />

      <TextField
        name={`${objKey}-end`}
        size="small"
        type="date"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        onChange={handleEndDateChange}
        defaultValue={
          filter?.value?.length > 0 ? moment(new Date(filter?.value[1])).format('YYYY-MM-DD') : null
        }
        sx={{
          width: ITEM_WIDTH + 20,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#676767'
            }
          }
        }}
      />
    </Box>
  );
};

const FilterItem = ({
  filters,
  setFilters
}: {
  filters: FiltersProps;
  setFilters: Dispatch<SetStateAction<FiltersProps>>;
}) => {
  const renderContent = (key: string) => {
    const handleRegularChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      setFilters({
        ...filters,
        [key]: { ...filters[key], value }
      });
    };

    const handleDateBetweenChange = ({ key, value }: { key: string; value: string[] }) => {
      setFilters({
        ...filters,
        [key]: { ...filters[key], value }
      });
    };

    const debouncedRegularChangeHandler = debounce(handleRegularChange, 500);

    switch (filters[key].type) {
      case 'string': {
        return (
          <TextField
            name={key}
            size="small"
            type={filters[key].type}
            defaultValue={filters[key].value ?? ''}
            label={`Search by ${filters[key].title ?? key}`}
            id={`Search by ${filters[key].title ?? key}`}
            variant="outlined"
            autoComplete="off"
            onChange={debouncedRegularChangeHandler}
            InputLabelProps={{ shrink: Boolean(filters[key].value) }}
            sx={{
              width: ITEM_WIDTH,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#676767'
                }
              }
            }}
          />
        );
      }
      case 'number': {
        return (
          <TextField
            name={key}
            size="small"
            type={filters[key].type}
            defaultValue={filters[key].value ?? null}
            label={`Search by ${filters[key].title ?? key}`}
            id={`Search by ${filters[key].title ?? key}`}
            variant="outlined"
            autoComplete="off"
            onChange={debouncedRegularChangeHandler}
            InputLabelProps={{ shrink: Boolean(filters[key].value) }}
            sx={{
              width: ITEM_WIDTH,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#676767'
                }
              }
            }}
          />
        );
      }
      case 'select': {
        return (
          <Autocomplete
            id="select-type"
            size="small"
            options={filters[key]?.options ?? []}
            value={
              filters[key]?.options?.find((option) => option.value === filters[key].value) || null
            }
            sx={{
              textTransform: 'capitalize',
              width: ITEM_WIDTH,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#676767'
                }
              }
            }}
            onChange={(event, item) => {
              setFilters({
                ...filters,
                [key]: { ...filters[key], value: item?.value }
                // [key]: Object.assign(filters[key], {value: item?.value}),
              });
            }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            renderOption={(el, option) => (
              <MenuItem
                {...el}
                value={option.value}
                style={{ textTransform: 'capitalize' }}
                key={option.value + option.label.trim().replace(' ', '_')}>
                {option.label}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField {...params} label={`Select ${filters[key].title ?? key}`} />
            )}
          />
        );
      }
      case 'date': {
        return (
          <TextField
            name={key}
            size="small"
            type={filters[key].type}
            defaultValue={filters[key].value ?? null}
            label={`Selecte ${filters[key].title ?? key}`}
            id={`Search by ${filters[key].title ?? key}`}
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            onChange={debouncedRegularChangeHandler}
            sx={{
              width: ITEM_WIDTH,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#676767'
                }
              }
            }}
          />
        );
      }
      case 'date-between': {
        return (
          <DateRange
            objKey={key}
            filter={filters[key]}
            handleDateBetweenChange={handleDateBetweenChange}
          />
        );
      }
      case 'boolean': {
        return (
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={debouncedRegularChangeHandler}
                defaultChecked={filters[key].value ?? false}
              />
            }
            label={filters[key].title ?? key}
            labelPlacement="start"
            sx={{
              width: ITEM_WIDTH
            }}
          />
        );
      }
      default:
        return (
          <TextField
            name={key}
            size="small"
            type={filters[key].type}
            defaultValue={filters[key].value ?? ''}
            label={`Search by ${filters[key].title ?? key}`}
            id={`Search by ${filters[key].title ?? key}`}
            variant="outlined"
            autoComplete="off"
            onChange={debouncedRegularChangeHandler}
            InputLabelProps={{ shrink: Boolean(filters[key].value) }}
            sx={{
              width: ITEM_WIDTH,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#676767'
                }
              }
            }}
          />
        );
    }
  };

  return (
    <Grid
      container
      xs={12}
      // md={10.5}
      display="flex"
      flexGrow={1}
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}>
      {Object.keys(filters).map(
        (key: string) =>
          filters[key].selected && (
            <Grid key={key} xs="auto" display="flex">
              <IconButton
                onClick={() => {
                  // Clearing a single search is what this is for
                  setFilters({
                    ...filters,
                    [key]: { ...filters[key], selected: false, value: null }
                  });
                }}>
                <HighlightOffIcon />
              </IconButton>
              {renderContent(key)}
            </Grid>
          )
      )}
    </Grid>
  );
};

export default FilterItem;
