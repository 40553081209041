import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from './urls';
import { logInCheck } from 'pages/auth/authSlice';
import { AbilityContext } from 'common/casl/abilityContext';
import ability, { AppAbility, getRules } from 'common/casl/ability';

const Routers = () => {
  const isLoggedIn = useSelector(logInCheck);

  const routing = useRoutes(routes(isLoggedIn));

  useEffect(() => {
    ability.update(getRules());
    return () => {};
  });

  return (
    <AbilityContext.Provider value={ability as AppAbility}>
      {routing}
    </AbilityContext.Provider>
  );
};

export default Routers;
