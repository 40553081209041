import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type VehicleState = {
  total: number | null;
  items: any[];
};
const initialState: VehicleState = { total: null, items: [] };

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicleData: (
      state,
      action: PayloadAction<{ total: number; items: any[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setVehicleData } = vehicleSlice.actions;

export const selectAllVehicles = (state: RootState) => state.vehicle.items;

export default vehicleSlice.reducer;
