import { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Header from './header';
import SideBar from './sidebar';
import DrawerHeader from './DrawerHeader';
import Breadcrumbs from 'components/breadCrumb';

export default function Layout({ children }: { children: any }) {
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        // bgcolor: '#FCFAF9'
      }}
    >
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideBar openDrawer={open} handleDrawerClose={handleDrawerClose} />
      <Box
        component='main'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        flexGrow={1}
        padding={1}
        margin={1}
        gap={2}
      >
        <DrawerHeader />
        <Box display='flex'>
          <Breadcrumbs />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
