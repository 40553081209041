import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type ServiceCategory = {
  serviceCategoryID: number;
  serviceCategoryName: string;
  serviceCategoryDescription?: string;
  serviceCategoryImage?: string;
  serviceCategoryEnabled: 'Y' | 'N';
  createdDate: Date;
  createdBy: string;
  updatedDate?: Date;
  updatedBy?: string;
  mediaUrl?: string;
};

type GetAllServiceResponseDTO = {
  serviceID: number;
  serviceName: string;
  serviceDescription?: string;
  serviceImage?: string;
  serviceEnabled: 'Y' | 'N';
  serviceCategoryID: number;
  createdDate: Date;
  createdBy: string;
  updatedDate?: Date;
  updatedBy?: string;
  category?: ServiceCategory;
};

type ServiceState = {
  total: number | null;
  items: GetAllServiceResponseDTO[];
};
const initialState: ServiceState = { total: null, items: [] };

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceData: (
      state,
      action: PayloadAction<{
        total: number;
        items: GetAllServiceResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setServiceData } = serviceSlice.actions;

export const selectAllService = (state: RootState) => state.service.items;

export default serviceSlice.reducer;
