import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Paper,
  Typography,
  ListItemAvatar,
  Avatar,
  CircularProgress
} from '@mui/material';
import moment from 'moment';
// import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';

import {
  NotificationCategoryKey,
  NotificationItem,
  useLazyListNotificationsQuery
} from 'app/services/messaging';
import ability, { SUBJECTS } from 'common/casl/ability';

const LIMIT = 15;

const RenderIcon = ({ key }: { key: NotificationCategoryKey }) => {
  // const theme = useTheme();
  const iconPath: string = {
    JOBS: 'hugeicons:new-job', // arcticons:jobstreet
    CUSTOMERS: 'mdi:user-circle-outline',
    TECHNICIANS: 'mdi:worker-outline'
  }[key];

  return (
    <Icon
      icon={iconPath}
      width="2rem"
      height="2rem"
      color="#b87e52"
      //  color={theme.palette.primary.main}
    />
  );
};

const ListNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const history = useNavigate();

  const disabledButton = offset + LIMIT > total;

  const goToPage = (row: any) => {
    history('new');
  };

  const [fetchNotifications, { data, isFetching, isLoading }] = useLazyListNotificationsQuery();

  const loadMoreNotifications = useCallback(async () => {
    await fetchNotifications({ limit: LIMIT, offset });
  }, [fetchNotifications, offset]);

  useEffect(() => {
    if (data?.items) {
      setNotifications((prevNotifications: any) => [...prevNotifications, ...data.items]);
      setTotal(data.total);
      setOffset((prevOffset) => prevOffset + LIMIT);
    }
  }, [data]);

  useEffect(() => {
    // Initial load
    loadMoreNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid xs={12} container spacing={2} display="flex" justifyContent="center" alignItems="center">
      {ability.can('CREATE', SUBJECTS.PUSH_NOTIFICATIONS) && (
        <Grid xs={4} xsOffset={8} display="flex" justifyContent="right" sx={{ mt: -6 }}>
          <Button
            variant="contained"
            onClick={goToPage}
            startIcon={<AddIcon />}
            sx={{ height: '36px' }}>
            Send notifications
          </Button>
        </Grid>
      )}
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          gap: 4,
          top: 20
        }}>
        <Box
          sx={{
            width: '100%',
            maxWidth: 760,
            bgcolor: 'background.paper'
          }}>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {notifications.map((notification: NotificationItem) => {
              return (
                <ListItem
                  component={Paper}
                  key={notification.adminNotificationID}
                  onClick={() => {
                    history(
                      `/${String(notification?.adminNotificationCategory).toLowerCase()}/${
                        notification?.categoryID
                      }`
                    );
                  }}
                  secondaryAction={
                    <Typography fontSize={'12px'} fontWeight={500} component="span">
                      {moment(notification.createdDate).format('hh:mm A')}
                    </Typography>
                  }
                  sx={{
                    // p: 2,
                    boxShadow: 3
                  }}>
                  <ListItemAvatar>
                    <Avatar>{RenderIcon({ key: notification.adminNotificationCategory })}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.adminNotificationTitle}
                    secondary={notification.adminNotificationDescription}
                    sx={{ cursor: 'pointer' }}
                  />
                </ListItem>
              );
            })}
          </List>

          {isLoading || isFetching ? (
            <Box display="flex" flexGrow={1} justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Button onClick={loadMoreNotifications} disabled={disabledButton} variant="contained">
              Load More
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ListNotifications;
