import { Grid } from '@mui/material';
import JobQuey from './JobQue';
import { useEffect, useState } from 'react';
import MyJobFollowup from './MyJobFollowup';

export default function AgentDashboard() {
  const [isCallCenter, setIsCallCenter] = useState(false);
  const [isSupervisor, setIsSuperVisor] = useState(false);

  const loadData = () => {
    const user = localStorage.getItem('user');
    const roleCode = JSON.parse(user || '{}')?.roleCode;
    if (roleCode && (roleCode === 3003 || roleCode === 3002)) {
      setIsCallCenter(true);
      if (roleCode === 3002) {
        setIsSuperVisor(true);
      }
    } else {
      setIsSuperVisor(false);
      setIsCallCenter(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!isCallCenter) {
    return <div></div>;
  }

  return (
    <Grid container spacing={2} paddingTop={0}>
      {/* <Grid item xs={12} padding={0} paddingTop={0}>
        <Typography fontSize={18} fontWeight={700} variant="h5" textAlign={'center'}>
          Agent Dashboard
        </Typography>
      </Grid> */}

      <Grid item xs={12}>
        <JobQuey isSupervisor={isSupervisor} />
      </Grid>
      {!isSupervisor && (
        <Grid item xs={12}>
          <MyJobFollowup />
        </Grid>
      )}
    </Grid>
  );
}
