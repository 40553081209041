/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useListJobQueQuery } from 'app/services/dashboard';
import FilterButton, { FiltersProps } from 'components/filterComponent/FilterButton';
import FilterItem from 'components/filterComponent/FilterItem';
import { DEFAULT_LIMIT, Job_Status } from 'utils/constants';
import { getFollowupStatusList, getStatusChip } from 'utils/followup';

export interface JobQueyProps {
  isSupervisor: boolean;
}

const JobStatusOptions: any[] = Object.keys(Job_Status)
  .filter((v) => isNaN(Number(v)))
  .map((el) => {
    return {
      label: el,
      value: Job_Status[el as keyof typeof Job_Status]
    };
  });

export default function JobQuey(props: JobQueyProps) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_LIMIT,
    page: 0
  });
  const [filters, setFilters] = useState<FiltersProps>({
    status: {
      title: 'Status',
      value: null,
      selected: false,
      type: 'select',
      options: JobStatusOptions
    },
    followupStatus: {
      title: 'Follow up Status',
      value: null,
      selected: false,
      type: 'select',
      options: getFollowupStatusList(true)
    },
    createdDate: {
      title: 'Created Date',
      value: null,
      selected: false,
      type: 'date'
    }
  });

  const { isLoading, data } = useListJobQueQuery(
    {
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      status: filters?.status?.value ?? undefined,
      followupStatus: filters?.followupStatus?.value ?? undefined,
      createdDate: filters?.createdDate?.value ?? undefined
    },
    {
      pollingInterval: 3000
    }
  );

  const nav = useNavigate();

  const columns = (onViewClick: any) => [
    {
      field: 'jobID',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 250,
      minWidth: 100,
      flex: 0.5
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 250,
      minWidth: 100,
      flex: 0.5
    },
    {
      field: 'followupStatus',
      headerName: 'Follow up Status',
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 350,
      flex: 1.5,
      renderCell: (rowData: any) => {
        return getStatusChip(rowData?.row?.followupStatus);
      }
    },
    {
      field: 'stateName',
      headerName: 'State',
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100
    },
    {
      field: 'districtName',
      headerName: 'District',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 250,
      flex: 1,
      renderCell: (rowData: any) => {
        return moment(new Date(rowData?.row?.createdDate)).format('DD-MM-YYYY');
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (rowData: any) => {
        return (
          <Tooltip title="Take job">
            <Button onClick={() => onViewClick(rowData?.row?.jobID, rowData?.row?.followupStatus)}>
              <Icon icon="oi:action-undo" width="1.3rem" height="1.3rem" />
            </Button>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
      sortable: false
    }
  ];

  const handleViewClick = (jobID: any, status: string) => {
    if (props.isSupervisor) {
      return nav(`/jobs/${jobID}`);
    }
    nav(`/jobs/${jobID}?type=assign&status=${status}`);
  };

  return (
    <Grid
      xs={12}
      container
      spacing={2}
      display="flex"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      sx={{ border: '1px solid #f0f0f0' }}>
      <Grid xs={12} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h5">Follow up Que</Typography>
      </Grid>
      <Grid xs={12} display="flex" alignItems="center" justifyContent="flex-start">
        <FilterButton filters={filters} setFilters={setFilters} />
      </Grid>
      <Grid xs={12} container display="flex" flexGrow={1}>
        <FilterItem filters={filters} setFilters={setFilters} />
      </Grid>
      <Grid xs={12}>
        <DataGrid
          columns={columns(handleViewClick)}
          style={{ minHeight: 300 }}
          pagination={true}
          pageSizeOptions={[10, 25, 50, 100]}
          getRowId={(row) => row?.jobID}
          rows={data?.items ?? []}
          loading={isLoading}
          rowCount={data?.total ?? 0}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
    </Grid>
  );
}
