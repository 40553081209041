import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

type ToastMessageType = {
  message: string;
  type: 'success' | 'error';
  open: boolean;
  key?: string;
};

const initialState: ToastMessageType = {
  open: false,
  message: '',
  type: 'success',
  key: '',
};

export const toastMessageSlice = createSlice({
  name: 'toastMessage',
  initialState,
  reducers: {
    openToastMessage: (
      state,
      action: PayloadAction<{
        message: string;
        type: 'success' | 'error';
        key?: string;
      }>
    ) => {
      const { message, type, key } = action.payload;
      state.open = true;
      state.message = message;
      state.type = type;
      state.key = key ?? '';
    },
    closeToastMessage: (state) => {
      state.open = false;
      state.message = '';
    },
  },
});

export const { openToastMessage, closeToastMessage } =
  toastMessageSlice.actions;

export const selectToastMessage = (state: RootState) => state.toastMessage;

export default toastMessageSlice.reducer;
