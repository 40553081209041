import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useListStaffQuery } from 'app/services/staff';
import { selectAllStaff, setStaffData } from 'pages/staffs/staffSlice';
import { DEFAULT_LIMIT } from 'utils/constants';

const useStaffList = () => {
  const [staffTotal, setStaffTotal] = useState(DEFAULT_LIMIT);
  const dispatch = useDispatch();

  const { data: staffData, isLoading: staffListLoading } = useListStaffQuery({
    limit: staffTotal,
    offset: 0
  });

  useEffect(() => {
    if (staffData) {
      dispatch(
        setStaffData({
          total: staffData?.total,
          items: staffData?.items
        })
      );
      setStaffTotal(staffData?.total > DEFAULT_LIMIT ? staffData?.total : DEFAULT_LIMIT);
    }

    return () => {};
  }, [staffData, dispatch]);

  const staffList = useSelector(selectAllStaff);

  return { staffList, staffListLoading };
};

export default useStaffList;
