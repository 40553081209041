import 'leaflet/dist/leaflet.css';
import { ReactElement, useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

type LeafletMapType = {
  children: ReactElement;
  center?: [number, number];
};

export const RecenterAutomatically = ({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);
  return null;
};

const LeafletMap = ({ children, center }: LeafletMapType) => {
  return (
    <MapContainer
      style={{ height: '50vh', width: '80%' }}
      center={center || [25.140628423514798, 55.398332468906055]}
      // center={[9.940107504539895, 76.36974334716798]}
      zoom={13}
      zoomAnimation={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
    </MapContainer>
  );
};

export default LeafletMap;
