import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useAdminLogoutMutation, useExtendSessionMutation } from 'app/services/auth';
import TimeOutModal from './TimeOutModal';
import { logout } from 'pages/auth/authSlice';

const MINUTE_MS = 5 * 60 * 1000;

const TimeOutHandler = () => {
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [storedShiftEndTime, setStoredShiftEndTime] = useState(
    localStorage.getItem('shiftEndTime') ?? moment().add(10, 'hours').toISOString()
  );

  const shiftEndMoment = moment(storedShiftEndTime);

  const dispatch = useDispatch();

  const [adminLogout] = useAdminLogoutMutation();
  const [extendSession, { data: extendedTillData, isSuccess }] = useExtendSessionMutation();

  useEffect(() => {
    if (extendedTillData && isSuccess) {
      const shiftEndTime = moment().add(15, 'minutes');
      setStoredShiftEndTime(extendedTillData?.extendedTill ?? shiftEndTime.toISOString());
      localStorage.setItem(
        'shiftEndTime',
        extendedTillData?.extendedTill ?? shiftEndTime.toISOString()
      );
    }
    return () => {};
  }, [extendedTillData, isSuccess]);

  const handleClose = () => {
    setOpen(false);
    dispatch(logout());
    adminLogout({});
  };

  const handleSubmit = () => {
    setOpen(false);
    setProgress(0);
    extendSession({});
  };

  useEffect(() => {
    const checkTime = () => {
      const currentTime = moment();
      if (shiftEndMoment.isSameOrBefore(currentTime)) {
        setOpen(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkTime, 1 * 1000);

    return () => clearInterval(interval);
  }, [shiftEndMoment]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const intervalDuration = MINUTE_MS / 100;
    if (open) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
      }, intervalDuration);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [open]);

  useEffect(() => {
    if (progress === 100) {
      dispatch(logout());
      adminLogout({});
    }
    return () => {};
  }, [adminLogout, dispatch, progress]);

  return (
    <TimeOutModal
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Are you still working?"
      progress={progress}
    />
  );
};

export default TimeOutHandler;
