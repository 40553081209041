import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = {
  current: null as google.maps.places.AutocompleteService | null,
};

const placesService = {
  current: null as google.maps.places.PlacesService | null,
};

const geocoder = { current: null as google.maps.Geocoder | null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  place_id: string; // Add place_id to the type
  structured_formatting: StructuredFormatting;
}

export default function PlacesAutocomplete({
  setFormValues,
  setMarkerPosition,
  resetPosition,
  setResetPosition,
}: {
  setFormValues: any;
  setMarkerPosition: any;
  resetPosition: boolean;
  setResetPosition: any;
}) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if ((window as any).google && !placesService.current) {
      const map = new (window as any).google.maps.Map(
        document.createElement('div')
      );
      placesService.current = new (
        window as any
      ).google.maps.places.PlacesService(map);
    }

    if ((window as any).google && !geocoder.current) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (isLatLngArray(inputValue)) {
      const [lat, lng] = inputValue.split(',').map(Number);
      reverseGeocode(lat, lng);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handlePlaceSelect = (place: PlaceType | null) => {
    if (!place || !placesService.current) return;

    placesService.current.getDetails(
      { placeId: place.place_id },
      (result, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          result?.geometry
        ) {
          const location = result.geometry.location;
          setMarkerPosition([location?.lat(), location?.lng()]);
          setFormValues('address', result?.formatted_address);
          setFormValues('area', result?.vicinity);
        }
      }
    );
  };

  const isLatLngArray = (input: string) => {
    const latLngArray = input.split(',');
    if (latLngArray.length !== 2) return false;
    const [lat, lng] = latLngArray.map(Number);
    return !isNaN(lat) && !isNaN(lng);
  };

  const reverseGeocode = (lat: number, lng: number) => {
    if (!geocoder.current) return;
    geocoder.current.geocode({ location: { lat, lng } }, (results, status) => {
      //@ts-ignore
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        //@ts-ignore
        const result = results[0];
        const place: PlaceType = {
          description: result.formatted_address,
          place_id: result.place_id,
          structured_formatting: {
            main_text: result.formatted_address,
            secondary_text: '',
          },
        };
        setOptions([place]);
      }
    });
  };

  React.useEffect(() => {
    if (resetPosition) {
      setValue(null);
      setFormValues('address', '');
      setFormValues('area', '');
    }
    return () => {};
  }, [resetPosition, setFormValues]);

  return (
    <Autocomplete
      id='google-map-places'
      // sx={{ width: 300 }}
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText='No locations'
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handlePlaceSelect(newValue);
        setResetPosition(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label='Add a location' fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component='span'
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
