import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState: any = {
  earningsReport: null,
  selectedEarnings: null,
  generalReport: null,
  kpiReport: null,
  monthwiseReport: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setGeneralReport: (
      state,
      action: PayloadAction<{ generalReport: any }>
    ) => {
      const { generalReport } = action.payload;
      state.generalReport = generalReport;
    },
    setEarningsReport: (
      state,
      action: PayloadAction<{ earningsReport: any }>
    ) => {
      const { earningsReport } = action.payload;
      state.earningsReport = earningsReport;
    },
    setKpiReport: (state, action: PayloadAction<{ kpiReport: any }>) => {
      const { kpiReport } = action.payload;
      state.kpiReport = kpiReport;
    },
    setSelectedEarnings: (
      state,
      action: PayloadAction<{ selectedEarnings: any }>
    ) => {
      state.selectedEarnings = action.payload;
    },
    setMonthwiseReport: (
      state,
      action: PayloadAction<{ monthwiseReport: any }>
    ) => {
      state.monthwiseReport = action.payload;
    },
  },
});

export const {
  setGeneralReport,
  setEarningsReport,
  setKpiReport,
  setSelectedEarnings,
  setMonthwiseReport,
} = reportSlice.actions;

export const selectGeneralReport = (state: RootState) =>
  state.report.generalReport;
export const selectEarningsReport = (state: RootState) =>
  state.report.earningsReport;
export const selectKpiReport = (state: RootState) => state.report.kpiReport;
export const selectMonthwiseReport = (state: RootState) =>
  state.report.monthwiseReport;
export const selectedEarnings = (state: RootState) =>
  state.report.selectedEarnings;

export default reportSlice.reducer;
