import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type GetAllDocumentResponseDTO = {
  documentID: number;
  documentTitle: string;
  documentEnabled: "Y" | "N";
  createdDate: Date;
  createdBy: string;
  updatedDate?: Date;
  updatedBy?: string;
};

// type DocumentState = GetAllDocumentResponseDTO[];

const initialState: GetAllDocumentResponseDTO[] = [];

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocumentData: (
      state,
      action: PayloadAction<GetAllDocumentResponseDTO[]>
    ) => {
      state = action.payload;
      return state
    },
  },
});

export const { setDocumentData } = documentSlice.actions;

export const selectAllDocument = (state: RootState) => state.document;

export default documentSlice.reducer;
