import { Suspense, ComponentType } from 'react';
import Loader from './Loader';

// Define the props type for the component being wrapped by Loadable
type WrappedComponentProps = {
  // Define the props for your wrapped component here
  // For example:
  // someProp: string;
};

// Define the Loadable HOC with TypeScript generics
const Loadable =
  <P extends WrappedComponentProps>(Component: ComponentType<P>) =>
  (props: P) =>
    (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
