import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';

import { store } from 'app/store';
import { logout } from 'pages/auth/authSlice';
import { useAdminLogoutMutation } from 'app/services/auth';

const HeaderIcons = () => {
  const history = useNavigate();
  const [adminLogout] = useAdminLogoutMutation();
  return (
    <Fragment>
      <Tooltip title="Notifications">
        <IconButton color="primary" onClick={() => history('/settings/push-notification')}>
          <NotificationsNoneIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Logout">
        <IconButton
          color="primary"
          onClick={() => {
            adminLogout({});
            store.dispatch(logout());
          }}>
          <LogoutIcon />
        </IconButton>
      </Tooltip>

      {/* {iconList.map((icon, i) => (
        <Fragment key={i}>
          <Tooltip title={icon.toolTipTitle}>
            <IconButton color="primary" onClick={icon.actionFunction}>
              {<icon.icon />}
            </IconButton>
          </Tooltip>
        </Fragment>
      ))} */}
    </Fragment>
  );
};

export default HeaderIcons;
