import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
// import moment from 'moment';

import {
  useDownloadReportMutation,
  useGetGeneralReportQuery,
} from 'app/services/report';
import { DEFAULT_LIMIT, ReportType } from 'utils/constants';
import ability, { SUBJECTS } from 'common/casl/ability';

const BannerItem = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_LIMIT,
    page: 0,
  });

  const { bannerId } = useParams();

  const { data, isLoading /*, isFetching, isError, error*/ } =
    useGetGeneralReportQuery({
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      id: bannerId,
    });

  const [
    downloadReport,
    {
      data: downloadReportData,
      isLoading: downloadReportLoading,
      isSuccess: downloadReportSuccess,
    },
  ] = useDownloadReportMutation();

  const handleDownload = () => {
    downloadReport({
      bannerID: bannerId,
      reportType: ReportType.OFFER_REPORT,
    });
  };

  useEffect(() => {
    if (
      downloadReportSuccess &&
      downloadReportData &&
      downloadReportData.data
    ) {
      window.open(downloadReportData?.data, '_blank');
    }
    return () => {};
  }, [downloadReportData, downloadReportSuccess]);

  const columns: GridColDef[] = [
    {
      field: 'technicianID',
      headerName: '#',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'BannerName',
      headerName: 'Banner Name',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'technicianEmail',
      headerName: 'Mobile',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'technicianPhone',
      headerName: 'Email',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    // {
    //   field: 'Date',
    //   headerName: 'Date',
    //   flex: 1,
    //   minWidth: 100,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params: GridRenderCellParams<any>) => {
    //     return (
    //       <>{moment(new Date(params.row.offerStart)).format('DD MMMM YYYY')}</>
    //     );
    //   },
    // },
  ];

  return (
    <Grid
      xs={12}
      container
      spacing={2}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Grid xs={12} display='flex' justifyContent='right'>
        {ability.can('CREATE', SUBJECTS.OFFERS) && (
          <LoadingButton
            sx={{ height: '54px', mt: 1 }}
            onClick={handleDownload}
            endIcon={<FileDownloadOutlinedIcon />}
            loading={downloadReportLoading}
            loadingPosition='end'
            variant='outlined'
          >
            <span>Export</span>
          </LoadingButton>
        )}
      </Grid>

      <Grid xs={12}>
        <DataGrid
          rows={data?.item ?? []}
          columns={columns}
          rowCount={data?.total ?? 0}
          loading={isLoading}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          paginationMode='server'
          onPaginationModelChange={setPaginationModel}
          sx={{ height: 750, width: '100%' }}
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  );
};

export default BannerItem;
