import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import { Breakpoint } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface ModalProps {
  open: boolean;
  progress: number;
  title?: string | null;
  onClose: () => void;
  onSubmit?: () => void;
  width?: Breakpoint;
}

const TimeOutModal: React.FC<ModalProps> = ({
  open,
  progress,
  title = null,
  onClose,
  onSubmit,
  width = 'sm'
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            return;
          }
          onClose();
        }}
        maxWidth={width}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        fullWidth>
        {title && <DialogTitle>{title}</DialogTitle>}
        <Divider />
        <DialogContent>
          <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} gap={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  progress
                )}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="text" onClick={onClose} sx={{ textTransform: 'none' }}>
            Logout
          </Button>
          <Button type="submit" variant="text" onClick={onSubmit} sx={{ textTransform: 'none' }}>
            Continue Session
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TimeOutModal;
