import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useListServiceCategoriesQuery } from 'app/services/service-categories';
import { selectAllCategory, setCategoryData } from 'pages/services/category/categorySlice';
import { DEFAULT_LIMIT } from 'utils/constants';

const useServiceCategoryList = () => {
  const [serviceCategoryTotal, setServiceCategoryTotal] = useState(DEFAULT_LIMIT);

  const dispatch = useDispatch();

  const { data: serviceCategoriesData, isLoading } = useListServiceCategoriesQuery({
    limit: serviceCategoryTotal,
    offset: 0
  });

  useEffect(() => {
    if (serviceCategoriesData) {
      dispatch(
        setCategoryData({
          total: serviceCategoriesData?.total,
          items: serviceCategoriesData?.items
        })
      );
      setServiceCategoryTotal(
        serviceCategoriesData?.total > DEFAULT_LIMIT ? serviceCategoriesData?.total : DEFAULT_LIMIT
      );
    }

    return () => {};
  }, [serviceCategoriesData, dispatch]);

  const serviceCategoryList = useSelector(selectAllCategory);

  return { serviceCategoryList, serviceCategoryLoading: isLoading };
};

export default useServiceCategoryList;
