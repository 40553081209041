import { AdminLoginUser } from 'pages/auth/authSlice';
import { api } from './api';

export interface UserResponse {
  user: AdminLoginUser;
  accessToken: string;
  shiftEndTime?: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/admin-login',
        method: 'POST',
        body: credentials
      })
    }),
    adminLogout: builder.mutation({
      query: () => ({
        url: '/auth/admin-logout',
        method: 'POST',
        headers: {
          Accept: '*/*'
        }
      })
    }),
    extendSession: builder.mutation({
      query: () => ({
        url: '/auth/admin/extend-session',
        method: 'POST'
      })
    })
  })
});

export const { useLoginMutation, useAdminLogoutMutation, useExtendSessionMutation } = authApi;
