import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

type PrewarrantyInspectionResponse = {
  inspectionID: number;
  statusName: string;
  statusCode: string;
  vinNumber: string;
  customerName: string;
  customerContactNo: string;
  createdDate: string;
  make: string;
  model: string;
  jobID: number;
};

type InspectionState = {
  total: number | null;
  items: PrewarrantyInspectionResponse[];
};
const initialState: InspectionState = { total: null, items: [] };

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    setInspectionData: (
      state,
      action: PayloadAction<{ total: number; items: PrewarrantyInspectionResponse[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setInspectionData } = inspectionSlice.actions;

export const selectAllInspections = (state: RootState) => state.inspection.items;

export default inspectionSlice.reducer;
