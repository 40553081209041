import {
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
  useEffect,
} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import { FilterIcon } from 'components/icons';

export interface OptionTypes {
  label: string;
  value: string | number;
}
export interface FiltersPropsItem {
  value: string | null | any | [];
  selected: boolean;
  type: string;
  title?: string;
  options?: OptionTypes[] | null;
}
export interface FiltersProps {
  [key: string]: FiltersPropsItem;
}

const FilterButton = ({
  filters,
  setFilters,
}: {
  filters: FiltersProps;
  setFilters: Dispatch<SetStateAction<FiltersProps>>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [oneOfFiltersSelected, setOneOfFiltersSelected] =
    useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Initialize a flag to track if any filter is selected
    let anyFilterSelected = false;
    // Check if any filter is selected
    Object.keys(filters).forEach((key) => {
      if (filters[key].selected) {
        // setOneOfFiltersSelected(true);
        anyFilterSelected = true;
      }
    });
    // Update the state based on whether any filter is selected
    setOneOfFiltersSelected(anyFilterSelected);

    return () => {
      // setOneOfFiltersSelected(false);
    };
  }, [filters]);

  return (
    <Box display='flex'>
      <Button
        id='fade-button'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<FilterIcon sx={{ mt: 0.5 }} />}
        variant='outlined'
        sx={{ width: '172px', height: '48px' }}
        // fullWidth
      >
        show filters
      </Button>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        style={{ textTransform: 'capitalize' }}
      >
        {Object.keys(filters).map(
          (key: string) =>
            !filters[key].selected && (
              <MenuItem
                style={{ textTransform: 'capitalize' }}
                key={key}
                onClick={() => {
                  setFilters({
                    ...filters,
                    [key]: { ...filters[key], selected: true },
                  });
                  handleClose(); // for close menu after select a filter
                }}
              >
                {filters[key].title ?? key}
              </MenuItem>
            )
        )}
        {oneOfFiltersSelected && (
          <MenuItem
            style={{ textTransform: 'capitalize' }}
            onClick={() => {
              const updatedVal = Object.keys(filters).map((key) => ({
                [key]: { ...filters[key], selected: false, value: null },
              }));
              setFilters(Object.assign({}, ...updatedVal));
              handleClose(); // for close menu after clear filters
            }}
          >
            Remove All Filters
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default FilterButton;
