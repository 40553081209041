import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type GetAllCategoryResponseDTO = {
  serviceCategoryID: number;
  serviceCategoryName: string;
  serviceCategoryDescription?: string;
  serviceCategoryImage?: string;
  serviceCategoryEnabled: 'Y' | 'N';
  createdDate: Date;
  createdBy: string;
  updatedDate?: Date;
  updatedBy?: string;
  mediaUrl?: string;
};

type CategoryState = {
  total: number | null;
  items: GetAllCategoryResponseDTO[];
};
const initialState: CategoryState = { total: null, items: [] };

const serviceCategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryData: (
      state,
      action: PayloadAction<{
        total: number;
        items: GetAllCategoryResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setCategoryData } = serviceCategorySlice.actions;

export const selectAllCategory = (state: RootState) =>
  state.serviceCategory.items;

export default serviceCategorySlice.reducer;
