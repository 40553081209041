import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PaymentIcon from '@mui/icons-material/Payment';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
// import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';

import { SUBJECTS } from 'common/casl/ability';

const menuList = [
  {
    text: 'Dashboard',
    link: '/',
    icon: () => <DashboardIcon color="primary" />,
    type: 'Home',
    permission: SUBJECTS.HOME
  },
  {
    text: 'Jobs',
    link: 'jobs',
    icon: () => <WorkOutlineOutlinedIcon color="primary" />,
    type: '/jobs/',
    permission: SUBJECTS.JOBS,
    items: [
      {
        text: 'All Jobs',
        link: 'jobs',
        icon: () => <ReorderOutlinedIcon color="primary" />,
        type: '/jobs/'
      },
      {
        text: 'Inspection',
        link: 'jobs/inspections',
        icon: () => <VisibilityOutlinedIcon color="primary" />,
        // icon: () => <NoteAltOutlinedIcon color="primary" />,
        type: '/jobs/inspections',
        permission: SUBJECTS.INSPECTIONS
      }
    ]
  },
  // {
  //   text: 'Garage',
  //   link: 'garage',
  //   icon: () => <GarageOutlinedIcon color='primary' />,
  //   type: 'garage',
  //   permission: SUBJECTS.GARAGE,
  // },
  {
    text: 'Customers',
    link: 'customers',
    icon: () => <PersonOutlineOutlinedIcon color="primary" />,
    type: 'customers',
    permission: SUBJECTS.CUSTOMERS
  },
  {
    text: 'Technicians',
    link: 'technicians',
    icon: () => <LocalShippingOutlinedIcon color="primary" />,
    type: 'technicians',
    permission: SUBJECTS.TECHNICIANS
  },
  {
    text: 'Services',
    link: 'services',
    icon: () => <FormatListBulletedIcon color="primary" />,
    type: 'services',
    permission: SUBJECTS.SERVICES
  },
  {
    text: 'Services Category',
    link: 'services-category',
    icon: () => <FormatListBulletedIcon color="primary" />,
    type: 'services-category',
    permission: SUBJECTS.SERVICE_CATEGORIES
  },
  {
    text: 'Payments',
    link: 'payments',
    icon: () => <PaymentIcon color="primary" />,
    type: 'payments',
    permission: SUBJECTS.PAYMENTS
  },
  {
    text: 'Reports',
    link: 'reports',
    icon: () => <PollOutlinedIcon color="primary" />,
    type: 'reports',
    permission: SUBJECTS.REPORTS,
    items: [
      {
        text: 'Earnings',
        link: 'reports/earnings',
        icon: () => <AttachMoneyOutlinedIcon color="primary" />,
        type: '/reports/earnings',
        permission: SUBJECTS.REPORTS
      },
      {
        text: 'KPI',
        link: 'reports/kpi',
        icon: () => <DonutSmallOutlinedIcon color="primary" />,
        type: '/reports/kpi',
        permission: SUBJECTS.KPI
      },
      {
        text: 'Monthwise',
        link: 'reports/monthwise',
        icon: () => <CalendarMonthOutlinedIcon color="primary" />,
        type: '/reports/monthwise',
        permission: SUBJECTS.MONTHLY_WISE_REPORT
      }
    ]
  },
  {
    text: 'Staffs',
    link: 'staffs',
    icon: () => <GroupOutlinedIcon color="primary" />,
    type: 'staffs',
    permission: SUBJECTS.USERS
  },
  {
    text: 'Settings',
    link: 'settings',
    icon: () => <SettingsOutlinedIcon color="primary" />,
    type: 'settings',
    permission: SUBJECTS.SETTINGS,
    items: [
      {
        text: 'Shift Management',
        link: 'settings/shifts',
        icon: () => <EventRepeatOutlinedIcon color="primary" />,
        type: '/settings/shifts',
        permission: SUBJECTS.SHIFTS
      },
      {
        text: 'Vehicles',
        link: 'settings/vehicles',
        icon: () => <CommuteOutlinedIcon color="primary" />,
        type: '/settings/vehicles',
        permission: SUBJECTS.VEHICLES
      },
      {
        text: 'Vehicles Brand',
        link: 'settings/vehicles-brand',
        icon: () => <CommuteOutlinedIcon color="primary" />,
        type: '/settings/vehicles-brand',
        permission: SUBJECTS.VEHICLE_BRANDS
      },
      {
        text: 'Document',
        link: 'settings/document',
        icon: () => <ArticleOutlinedIcon color="primary" />,
        type: '/settings/document',
        permission: SUBJECTS.DOCUMENT
      },
      {
        text: 'Push Notification',
        link: 'settings/push-notification',
        icon: () => <NotificationAddOutlinedIcon color="primary" />,
        type: '/settings/push-notification',
        permission: SUBJECTS.PUSH_NOTIFICATIONS
      },
      {
        text: 'Banners',
        link: 'settings/banners',
        icon: () => <PollOutlinedIcon color="primary" />,
        type: '/settings/banners',
        permission: SUBJECTS.BANNERS
      },
      {
        text: 'Agreements',
        link: 'settings/agreements',
        icon: () => <HistoryEduOutlinedIcon color="primary" />,
        // icon: () => <NoteAltOutlinedIcon color="primary" />,
        type: '/settings/agreements',
        permission: SUBJECTS.AGREEMENTS
      },
      {
        text: 'Job Types',
        link: 'settings/job-types',
        icon: () => <ChecklistOutlinedIcon color="primary" />,
        type: '/settings/job-types',
        permission: SUBJECTS.JOB_TYPES
      },
      {
        text: 'Languages',
        link: 'settings/languages',
        icon: () => <LanguageIcon color="primary" />,
        type: '/settings/languages',
        permission: SUBJECTS.LANGUAGES
      },
      {
        text: 'States',
        link: 'settings/states',
        icon: () => <LocationCityOutlinedIcon color="primary" />,
        type: '/settings/states',
        permission: SUBJECTS.STATES
      }
      // {
      //   text: 'Designations',
      //   link: 'settings/designations',
      //   icon: () => <SecurityOutlinedIcon color='primary' />,
      //   type: '/settings/designations',
      //   permission: SUBJECTS.SETTINGS,
      // },
    ]
  }
];

export default menuList;
