import { useState } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { drawerWidth } from 'utils/constants';
import HeaderIcons from './HeaderIcons';
import QuickJob from 'pages/jobs/QuickJob';
import ability, { SUBJECTS } from 'common/casl/ability';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Header = ({ open, handleDrawerOpen }: { open: boolean; handleDrawerOpen: any }) => {
  const [openQuickJob, setOpenQuickJob] = useState<boolean>(false);
  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');

  const handleOpen = () => setOpenQuickJob(true);
  const handleClose = () => {
    setOpenQuickJob(false);
  };

  return (
    <AppBar position="fixed" open={open} sx={{ bgcolor: 'white', boxShadow: 0.1 }}>
      <Toolbar>
        <IconButton
          // color='inherit'
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
            ':hover': {
              color: 'secondary'
            }
          }}>
          <MenuIcon />
        </IconButton>

        <Box
          display="flex"
          // alignItems='flex-end'
          alignItems="center"
          flexDirection="column"
          ml={2}>
          <Typography color="primary" noWrap fontSize="14px" textAlign="start">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography
            color="primary"
            noWrap
            component="div"
            fontSize="10px"
            textAlign="start"
            width="100%">
            {user?.staffID}
          </Typography>
        </Box>

        <Box sx={{ marginLeft: 'auto' }}>
          {ability.can('CREATE', SUBJECTS.JOBS) && (
            <Button
              variant="text"
              onClick={handleOpen}
              // startIcon={<AddIcon />}
              sx={{ height: '36px' }}>
              Quick Job
            </Button>
          )}
          <HeaderIcons />

          {openQuickJob && (
            <QuickJob title="Add Quick Job" open={openQuickJob} handleClose={handleClose} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
