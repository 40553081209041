import { DEFAULT_LIMIT, ListResponse, QueryParams, SuccessResponce } from 'utils/constants';
import { api } from './api';
import { GetAllCategoryResponseDTO } from 'pages/services/category/categorySlice';

export interface CreateServiceCategoryInputDTO {
  serviceName?: string;
  serviceDescription: string;
  serviceImage: string;
  serviceCategoryID?: number;
}

export interface UpdateServiceCategoryInputDTO {
  id: string;
  serviceName?: string;
  serviceDescription: string;
  serviceImage: string;
  serviceCategoryID?: number;
  serviceEnabled?: 'Y' | 'N';
}

type ServiceCategoriesListResponse = ListResponse<GetAllCategoryResponseDTO>;

export const serviceCategoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listServiceCategories: builder.query<ServiceCategoriesListResponse, QueryParams>({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, name, enabled } = params;
        let queryUrl = `/service-categories?limit=${limit}&offset=${offset}`;

        if (typeof name === 'string') {
          queryUrl += `&name=${name}`;
        }

        if (typeof enabled === 'boolean') {
          queryUrl += `&enabled=${enabled === true ? 'Y' : 'N'}`;
        }

        return queryUrl;
      },
      transformResponse: (res: ServiceCategoriesListResponse) => {
        let items = res?.items?.map((item: GetAllCategoryResponseDTO) => {
          return { ...item, id: item['serviceCategoryID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: any = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(
            ({ id }: { id: string }) => ({ type: 'ServiceCategory', id } as const)
          ) ?? [];
        return [...items, { type: 'ServiceCategory' as const, id: 'LIST' }];
      }
    }),
    createServiceCategory: builder.mutation<
      SuccessResponce,
      Partial<CreateServiceCategoryInputDTO>
    >({
      query(body) {
        return {
          url: `/services-categories`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body
        };
      },
      invalidatesTags: ['ServiceCategory']
    }),
    updateServiceCategory: builder.mutation<
      SuccessResponce,
      Partial<UpdateServiceCategoryInputDTO>
    >({
      query(body) {
        return {
          url: `/services-categories/${body.serviceCategoryID}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body
        };
      },
      invalidatesTags: ['ServiceCategory']
    })
  })
});

export const {
  useListServiceCategoriesQuery,
  useCreateServiceCategoryMutation,
  useUpdateServiceCategoryMutation
} = serviceCategoriesApi;
