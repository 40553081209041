import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type VehicleBrandState = {
  total: number | null;
  items: any[];
};
const initialState: VehicleBrandState = { total: null, items: [] };

const vehicleBrandSlice = createSlice({
  name: 'vehicleBrand',
  initialState,
  reducers: {
    setVehicleBrandData: (
      state,
      action: PayloadAction<{ total: number; items: any[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setVehicleBrandData } = vehicleBrandSlice.actions;

export const selectAllVehicleBrands = (state: RootState) =>
  state.vehicleBrand.items;

export default vehicleBrandSlice.reducer;
