import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Marker, useMapEvents } from 'react-leaflet';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import InputField from 'components/forms/InputField';
import ModalDialog from 'components/modalDialog/modalDialog';
import { DEFAULT_LIMIT, jobProviders, jobProviders1 } from 'utils/constants';
import useServiceCategoryList from 'hooks/useServiceCategoryList';
import LeafletMap, { RecenterAutomatically } from 'components/LeafletMap';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { useListVehiclesQuery } from 'app/services/vehicle';
import { QuickJobInput, useQuickJobMutation } from 'app/services/job';
import useStaffList from 'hooks/useStaffList';
import { openToastMessage } from 'app/slices/toastMessageSlice';
import { Box, Typography } from '@mui/material';

interface QuickJobProps {
  open: boolean;
  handleClose: () => void;
  title: string;
}

const ClickableMarker = ({
  position,
  setMarkerPosition,
  setResetPosition
}: {
  position: any;
  setMarkerPosition: any;
  setResetPosition: any;
}) => {
  const handleMarkerDragEnd = (e: any) => {
    const { lat, lng } = e.target.getLatLng();
    setMarkerPosition([lat, lng]);
    setResetPosition(true);
  };

  return (
    <Marker
      position={position}
      draggable={true}
      eventHandlers={{
        dragend: handleMarkerDragEnd
      }}
    />
  );
};

const MapClickHandler = ({ handleMapClick }: { handleMapClick: any }) => {
  useMapEvents({
    click: handleMapClick,
    drag: handleMapClick
  });
  return null;
};

const schema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
  customerMobile: Yup.string()
    .required('Customer Phone is required')
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
  regNumber: Yup.string()
    .required('Registration Number is required')
    .max(10, 'Please enter a valid registration number'),
  provider: Yup.string().required('Provider is required'),
  complaint: Yup.string().required('Complaint is required'),
  assignedAgent: Yup.string().required('Assigned Agent is required'),
  location: Yup.string().optional(),
  serviceCategory: Yup.number().required('Category is required').typeError('Category is required'),
  vehicle: Yup.number().required('Vehicle is required').typeError('Vehicle is required'),
  lat: Yup.number().required('Lat is required').typeError('Lat is required'),
  long: Yup.number().required('Long is required').typeError('Long is required'),
  jobSourceDetails: Yup.string().nullable()
});

const QuickJob: React.FC<QuickJobProps> = ({ open, handleClose, title }) => {
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    10.100129386088865, 76.35642101929527
  ]);
  const [resetPosition, setResetPosition] = useState(false);
  const [stateTotal, setStateTotal] = useState(DEFAULT_LIMIT);
  const { staffList, staffListLoading } = useStaffList();

  const { serviceCategoryList, serviceCategoryLoading } = useServiceCategoryList();

  const dispatch = useDispatch();

  const [createQuickJob, { data, isLoading, isSuccess /*data, error, isError*/ }] =
    useQuickJobMutation();

  const { data: vehicleData /*, isLoading , isFetching, isError, error*/ } = useListVehiclesQuery({
    limit: stateTotal,
    offset: 0
  });

  useEffect(() => {
    if (vehicleData) {
      setStateTotal(vehicleData?.total > DEFAULT_LIMIT ? vehicleData?.total : DEFAULT_LIMIT);
    }
    return () => {};
  }, [vehicleData]);

  useEffect(() => {
    if (data && data?.success) {
      dispatch(
        openToastMessage({
          type: 'success',
          message: data?.message
        })
      );
      handleClose();
    }

    return () => {};
  }, [data, dispatch, handleClose, isSuccess]);

  const methods = useForm<QuickJobInput>({
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = methods;

  const onSave = (values: any) => {
    values = {
      ...values,
      location: values.address
    };
    delete values.address;
    delete values.area;

    createQuickJob(values);
  };

  useEffect(() => {
    const lat = markerPosition[0];
    const lng = markerPosition[1];
    setValue('lat', lat, { shouldDirty: true, shouldValidate: true });
    setValue('long', lng, { shouldDirty: true, shouldValidate: true });
    return () => {};
  }, [markerPosition, setValue]);

  const handleMapClick = (e: any) => {
    setResetPosition(true);
    if (e.type === 'drag') return;
    const lat = e?.latlng?.lat ?? 0;
    const lng = e?.latlng?.lng ?? 0;
    setMarkerPosition([lat, lng]);
  };

  return (
    <ModalDialog
      open={open}
      cancelName={'cancel'}
      submitName={'save'}
      title={title}
      onSubmit={handleSubmit(onSave)}
      onClose={handleClose}
      loading={isLoading}
      width="lg">
      <form autoComplete="off">
        <Grid
          xs={12}
          container
          display="flex"
          alignContent="center"
          justifyItems="center"
          spacing={2}>
          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="customerName"
              margin="normal"
              fullWidth
              id="customerName"
              label="Customer Name"
              type="text"
              helperText={`${errors.customerName?.message ?? ''}`}
              error={!!errors.customerName}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="customerMobile"
              margin="normal"
              fullWidth
              id="customerMobile"
              label="Customer Mobile"
              type="text"
              helperText={`${errors.customerMobile?.message ?? ''}`}
              error={!!errors.customerMobile}
              inputProps={{
                maxLength: 10
              }}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <Controller
              control={control}
              name="vehicle"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  value={value || null}
                  disablePortal
                  id="select-vehicle"
                  fullWidth
                  options={vehicleData?.items}
                  getOptionKey={(option: any) =>
                    option?.vehicleID + '-' + option?.vehicleName?.replace(/\ /g, '')
                  }
                  renderOption={(props, opt: any) => {
                    return (
                      //@ts-ignore
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          padding: 1
                        }}
                        /* @ts-ignore */
                        {...props}>
                        <Typography>{opt?.vehicleName}</Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: 'primary.main'
                          }}>
                          - {opt?.Brand?.vehicleBrandName}
                        </Typography>
                      </Box>
                    );
                  }}
                  getOptionLabel={(option: any) => {
                    if (typeof option === 'number') {
                      const opts = vehicleData?.items.find(
                        (item: any) => item.vehicleID === option
                      );
                      return opts?.vehicleName;
                    }
                    return option.vehicleName;
                  }}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option?.vehicleID === value?.vehicleID || option?.vehicleID === value;
                  }}
                  onChange={(event, item: any) => {
                    onChange(item?.vehicleID);
                    setValue('vehicle', item?.vehicleID, {
                      shouldDirty: true,
                      shouldValidate: true
                    });
                  }}
                  // defaultValue={watch('vehicleID')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Vehicle"
                      margin="normal"
                      helperText={`${errors.vehicle?.message ?? ''}`}
                      error={!!errors.vehicle}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="regNumber"
              margin="normal"
              fullWidth
              id="regNumber"
              label="Registration Number"
              type="text"
              helperText={`${errors.regNumber?.message ?? ''}`}
              error={!!errors.regNumber}
              inputProps={{
                maxLength: 10,
                style: {
                  textTransform: 'uppercase'
                }
              }}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <InputField
              control={control}
              select
              name="provider"
              margin="normal"
              fullWidth
              id="provider"
              label="Job provider"
              type="text"
              helperText={`${errors.provider?.message ?? ''}`}
              error={!!errors.provider}
              style={{ textTransform: 'capitalize' }}>
              {[...jobProviders, ...jobProviders1].map((option: string) => (
                <MenuItem
                  key={`strong-source-${option}`}
                  value={`${option}`}
                  style={{ textTransform: 'capitalize' }}>
                  {option.replace(/_/gi, ' ').toLowerCase()}
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          <Grid xs={6} display="flex" alignContent="center" justifyItems="center">
            <InputField
              select
              control={control}
              name="assignedAgent"
              margin="normal"
              fullWidth
              id="assignedAgent"
              label="Assigned Agent"
              type="number"
              helperText={`${errors.assignedAgent?.message ?? ''}`}
              error={!!errors.assignedAgent}
              loading={staffListLoading}>
              {staffList?.filter((staff) => staff)?.map((staff: any) => (
                <MenuItem key={staff.adminID} value={staff.adminID}>
                  {`${staff?.firstName ?? ''} ${staff?.lastName ?? ''}`}
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <InputField
              control={control}
              select
              name="serviceCategory"
              margin="normal"
              fullWidth
              id="serviceCategory"
              label="Service Category"
              type="text"
              helperText={`${errors.serviceCategory?.message ?? ''}`}
              error={!!errors.serviceCategory}
              sx={{ mb: errors.serviceCategory ? -2 : 1 }}
              loading={serviceCategoryLoading}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 380
                  }
                }
              }}>
              {serviceCategoryList?.map((option: any) => (
                <MenuItem key={`strong-${option.id}`} value={`${option.id}`}>
                  {option.serviceCategoryName}
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          <Grid xs={12} display="flex" flexGrow={1} alignItems="center" justifyContent="center">
            <InputField
              control={control}
              name="complaint"
              multiline
              margin="normal"
              fullWidth
              id="complaint"
              label="Job Complaint"
              type="text"
              minRows={3}
              helperText={`${errors.complaint?.message ?? ''}`}
              error={!!errors.complaint}
            />
          </Grid>

          <Grid
            xs={6}
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            mt={2}>
            <PlacesAutocomplete
              setFormValues={setValue}
              setMarkerPosition={setMarkerPosition}
              resetPosition={resetPosition}
              setResetPosition={setResetPosition}
            />
          </Grid>

          <Grid xs={12} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <LeafletMap center={markerPosition}>
              <>
                <ClickableMarker
                  position={markerPosition}
                  setMarkerPosition={setMarkerPosition}
                  setResetPosition={setResetPosition}
                />
                <MapClickHandler handleMapClick={handleMapClick} />
                <RecenterAutomatically lat={markerPosition[0]} lng={markerPosition[1]} />
              </>
            </LeafletMap>
          </Grid>
        </Grid>
      </form>
    </ModalDialog>
  );
};

export default QuickJob;
