import { DEFAULT_LIMIT, ListResponse, QueryParams, SuccessResponce } from 'utils/constants';
import { api } from './api';
import {
  UserDetailsDto,
  UserLanguage,
  UserResponseDTO,
  UserRolesDto
} from 'pages/staffs/staffSlice';

export interface staffDTO {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  roleID: number;
  password: string;
  adminPhone: string;
  username: string;
  agentLanguages: Omit<UserLanguage, 'languageName'>[];
}

export interface userPermissionDTO {
  id?: string;
  permissionID: number;
}

export interface disableStaffDTO {
  staffID: string;
  reason: string;
}

type StaffListResponse = ListResponse<UserResponseDTO>;
type UserRolesListResponse = ListResponse<UserRolesDto>;

export const staffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listStaff: builder.query<StaffListResponse, QueryParams>({
      query: (params) => {
        const {
          limit = DEFAULT_LIMIT,
          offset = 0,
          name,
          username,
          email,
          status,
          date,
          role,
          phone,
          roleCode,
          stateID,
          districtID,
          showDeleted,
        } = params;
        let queryUrl = `/admin/user?limit=${limit}&offset=${offset}`;

        if (typeof name === 'string') {
          queryUrl += `&name=${name}`;
        }

        if (typeof roleCode === 'number') {
          queryUrl += `&roleCode=${roleCode}`;
        }

        if (typeof showDeleted === 'boolean') {
          queryUrl += `&showDeleted=${showDeleted ? 'Y' : 'N'}`;
        }

        if (typeof username === 'string') {
          queryUrl += `&username=${username}`;
        }

        if (typeof email === 'string') {
          queryUrl += `&email=${email}`;
        }

        if (typeof phone === 'string') {
          queryUrl += `&phone=${phone}`;
        }

        if (typeof status === 'boolean') {
          queryUrl += `&status=${status ? 'Y' : 'N'}`;
        }

        if (typeof date === 'object') {
          queryUrl += `&${date.map((date: any) => `date=${date}`).join('&')}`;
        }

        if (typeof role === 'number') {
          queryUrl += `&role=${role}`;
        }

        if (typeof stateID === 'number') {
          queryUrl += `&stateID=${stateID}`;
        }

        if (typeof districtID === 'number') {
          queryUrl += `&districtID=${districtID}`;
        }

        return queryUrl;
      },
      transformResponse: (res: StaffListResponse) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['adminID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: any = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(({ id }: { id: string }) => ({ type: 'User', id } as const)) ?? [];
        return [...items, { type: 'User' as const, id: 'LIST' }];
      }
    }),
    listAdminRoles: builder.query<UserRolesListResponse, QueryParams>({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, id, showDisabled } = params;
        let queryUrl = `/admin/roles?limit=${limit}&offset=${offset}`;
        if (typeof id === 'string') {
          queryUrl += `&id=${id}`;
        }

        if (typeof showDisabled === 'boolean') {
          queryUrl += `&showDisabled=${showDisabled ? 'Y' : 'N'}`;
        }

        return queryUrl;
      },
      transformResponse: (res: UserRolesListResponse) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['roleID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: any = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(({ id }: { id: string }) => ({ type: 'Role', id } as const)) ?? [];
        return [...items, { type: 'Role' as const, id: 'LIST' }];
      }
    }),
    listAuditLogs: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, id } = params;
        let queryUrl = `/admin/user/${id}/audit-log?limit=${limit}&offset=${offset}`;
        return queryUrl;
      },
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['roleID'] };
        });
        return { ...res, items };
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(({ id }: { id: string }) => ({ type: 'Role', id } as const)) ?? [];
        return [...items, { type: 'Role' as const, id: 'LIST' }];
      }
    }),
    getStaff: builder.query<UserDetailsDto, { id: string | number }>({
      query: (params) => {
        const { id } = params;
        const queryUrl = `/admin/user/${id}`;
        return queryUrl;
      },
      providesTags: ['User']
    }),
    createStaff: builder.mutation<SuccessResponce, Partial<staffDTO>>({
      query(body) {
        return {
          url: `/admin/user`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['User']
    }),
    updateStaff: builder.mutation<SuccessResponce, Partial<staffDTO>>({
      query(body) {
        const { id } = body;
        return {
          url: `/admin/user/${id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['User']
    }),
    addPermission: builder.mutation<userPermissionDTO, Partial<userPermissionDTO>>({
      query(body) {
        const { id, permissionID } = body;
        return {
          url: `/admin/user/${id}/permissions`,
          method: 'POST',
          body: { permissionID } //permissionID
        };
      },
      invalidatesTags: ['User']
    }),
    removePermission: builder.mutation<userPermissionDTO, Partial<userPermissionDTO>>({
      query(body) {
        const { id, permissionID } = body;
        return {
          url: `/admin/user/${id}/permissions`,
          method: 'DELETE',
          body: { permissionID }
          // headers: {
          //   'Content-Type': 'application/json',
          // },
        };
      },
      invalidatesTags: ['User']
    }),
    createRole: builder.mutation({
      query(body) {
        return {
          url: `/admin/roles`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Role']
    }),
    getStaffInternalRating: builder.query({
      query: (params) => {
        const { id } = params;
        const queryUrl = `/admin/user/${id}/internal-remark?limit=${params.limit}&offset=${params.offset}`;
        return {
          url: queryUrl,
          method: 'GET'
        };
      },
      providesTags: ['AdminRemak']
    }),
    createStaffInternalRating: builder.mutation({
      query(body) {
        const { id } = body;
        return {
          url: `/admin/user/${id}/internal-remark`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['AdminRemak']
    }),
    disableStaff: builder.mutation<SuccessResponce, Partial<disableStaffDTO>>({
      query(body) {
        return {
          url: `/admin/user/disable`,
          method: 'DELETE',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['User']
    })
  })
});

export const {
  useListStaffQuery,
  useLazyListStaffQuery,
  useCreateStaffMutation,
  useGetStaffQuery,
  useListAdminRolesQuery,
  useUpdateStaffMutation,
  useAddPermissionMutation,
  useRemovePermissionMutation,
  useListAuditLogsQuery,
  useCreateRoleMutation,
  useGetStaffInternalRatingQuery,
  useLazyGetStaffInternalRatingQuery,
  useCreateStaffInternalRatingMutation,
  useDisableStaffMutation
} = staffApi;
