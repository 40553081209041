import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type StateItem = {
  stateID: string;
  stateName: string;
};
type DistrictItem = {
  districtID: string;
  districtName: string;
  stateID: string;
};

type locationState = {
  states: {
    total: number | null;
    items: StateItem[] | [];
  };
  districts: {
    total: number | null;
    items: DistrictItem[] | [];
  };
};

const initialState: locationState = {
  states: { items: [], total: 0 },
  districts: { items: [], total: 0 }
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setStates: (state, action: PayloadAction<{ total: number; items: StateItem[] }>) => {
      const { total, items } = action.payload;
      state.states.total = total;
      state.states.items = items;
    },
    setDistricts: (state, action: PayloadAction<{ total: number; items: DistrictItem[] }>) => {
      const { total, items } = action.payload;
      state.districts.total = total;
      state.districts.items = items;
    }
  }
});

export const { setStates, setDistricts } = locationSlice.actions;

export const selectAllStates = (state: RootState) => state.location.states.items;
export const selectAllDistricts = (state: RootState) => state.location.districts?.items;

export default locationSlice.reducer;
