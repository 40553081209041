import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ShiftItem } from 'app/services/shift';
import { RootState } from 'app/store';
import { ListResponse } from 'utils/constants';

type ShiftState = ListResponse<ShiftItem>;

const initialState: ShiftState = { total: 0, items: [] };

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    setShiftData: (
      state,
      action: PayloadAction<{
        total: number;
        items: ShiftItem[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setShiftData } = shiftSlice.actions;

export const selectAllShift = (state: RootState) => state.shift.items;

export default shiftSlice.reducer;
