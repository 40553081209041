import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

type GetAllAgreementResponseDTO = {
  agreementID: number;
  agreementName: string;
  agreementContent: string;
  agreementEnabled: string;
  mediaURL: string;
  agreementType: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
};

type AgreementState = {
  total: number | null;
  items: GetAllAgreementResponseDTO[];
};
const initialState: AgreementState = { total: null, items: [] };

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    setAgreementData: (
      state,
      action: PayloadAction<{ total: number; items: GetAllAgreementResponseDTO[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setAgreementData } = agreementSlice.actions;

export const selectAllAgreements = (state: RootState) => state.agreement.items;

export default agreementSlice.reducer;
