import { DEFAULT_LIMIT, SuccessResponce } from 'utils/constants';
import { api } from './api';

export type NotificationCategoryKey = 'JOBS' | 'CUSTOMERS' | 'TECHNICIANS';

export type PushMessagesDTO = {
  title: string;
  body: string;
  appPlatform: string; // "CUSTOMER"
  imageUrl?: string;
  isTest?: boolean;
  testPhone?: string;
  districtID?: number;
  file?: File;
  link?: string;
};

export type SubscribeToTopicDTO = {
  token: string;
};

export type NotificationItem = {
  adminNotificationID: string;
  adminNotificationTitle: string;
  adminNotificationDescription: string;
  adminNotificationCategory: NotificationCategoryKey;
  adminNotificationRead: ['Y' | 'N'];
  categoryID: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
};

export const messagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    pushMessages: builder.mutation<SuccessResponce, Partial<PushMessagesDTO>>({
      query(body) {
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            if (key === 'file' && value instanceof File) {
              formData.append(key, value, value.name);
            } else {
              formData.append(key, String(value));
            }
          }
        });

        return {
          url: `/admin/messaging/push`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Messages']
    }),
    subscribeToTopic: builder.mutation<SubscribeToTopicDTO, Partial<SubscribeToTopicDTO>>({
      query(body) {
        return {
          url: `/admin/subscribe-to-topic`,
          method: 'POST',
          body
        };
      }
      // invalidatesTags: ['Messages'],
    }),
    unsubscribeFromTopic: builder.mutation<SubscribeToTopicDTO, Partial<SubscribeToTopicDTO>>({
      query(body) {
        return {
          url: `/admin/unsubscribe-from-topic`,
          method: 'POST',
          body
        };
      }
    }),
    listNotifications: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0 } = params;
        let queryUrl = `/admin/notifications?limit=${limit}&offset=${offset}`;

        return queryUrl;
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(
            ({ adminNotificationID }: { adminNotificationID: string }) =>
              ({ type: 'Messages', adminNotificationID } as const)
          ) ?? [];
        return [...items, { type: 'Messages' as const, id: 'LIST' }];
      }
    }),
    registerDevice: builder.mutation<SuccessResponce, string>({
      query(body) {
        return {
          url: `/admin/device/register`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'text/plain'
          }
        };
      }
    })
  })
});

export const {
  usePushMessagesMutation,
  useSubscribeToTopicMutation,
  useUnsubscribeFromTopicMutation,
  useListNotificationsQuery,
  useLazyListNotificationsQuery,
  useRegisterDeviceMutation
} = messagesApi;
