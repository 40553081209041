import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type SubItem = {
  title: string;
  description: string;
  value: string;
  type: 'TEXT' | 'NUMBER' | 'IMAGE' | 'SELECT' | 'BOOLEAN' | 'TEXT_AREA';
  options?: string[];
};

export type ParentItem = {
  title?: string;
  description?: string;
  configurations: {
    [key: string]: SubItem;
  };
};

export type ConfigItem = {
  [key: string]: ParentItem;
};

const initialState: ConfigItem = {};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<ConfigItem>) => action.payload,
  },
});

export const { setSettings } = settingsSlice.actions;

export const selectAllSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
