import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  ListItemText,
  ListItemButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';

import { DrawerContext } from './drawerProvider';
import { MenuItemType } from '.';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'common/casl/abilityContext';

const SingleLevel = ({ listItems }: { listItems: MenuItemType }) => {
  const theme = useTheme();
  const { isDrawerOpen } = useContext(DrawerContext);
  const { text, link, type } = listItems;
  const { pathname } = useLocation();
  const isActive = pathname === type;

  return (
    <ListItem
      disablePadding
      sx={{
        display: 'block'
      }}
      component={RouterLink as any}
      to={link}
      state={{ path: link }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: isDrawerOpen ? 'initial' : 'center',
          px: 2.5,
          '&.Mui-selected': {
            color: theme.palette.secondary.main // Change to the desired selected text color
          }
        }}
        selected={link === pathname.split('/')[1] || isActive}>
        <Tooltip title={!isDrawerOpen ? text : null} placement="right">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isDrawerOpen ? 3 : 'auto',
              justifyContent: 'center'
            }}>
            <listItems.icon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={text}
          sx={{
            opacity: isDrawerOpen ? 1 : 0,
            color: theme.palette.primary.main
            // '&:active': {
            //   color: theme.palette.secondary.main, // Change to the desired active color
            // },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const MultiLevel = ({ listItems }: { listItems: MenuItemType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const { text, items, link } = listItems;
  const { isDrawerOpen } = useContext(DrawerContext);
  const { pathname } = useLocation();

  const ability = useAbility(AbilityContext);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    // console.log(listItems);
  }, []);

  return (
    <React.Fragment>
      <ListItem
        disablePadding
        sx={{
          display: 'block'
        }}>
        <ListItemButton
          component={RouterLink as any}
          to={ability.can('READ', listItems?.permission || '') ? link : '/'}
          state={{ path: link }}
          sx={{
            minHeight: 48,
            justifyContent: isDrawerOpen ? 'initial' : 'center',
            px: 2.5,
            '&.Mui-selected': {
              color: theme.palette.secondary.main // Change to the desired selected text color
            }
          }}
          selected={link === pathname.split('/')[1]}>
          <Tooltip title={!isDrawerOpen ? listItems.text : null} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerOpen ? 3 : 'auto',
                justifyContent: 'center',
                ml: !isDrawerOpen ? 3 : 'auto'
              }}>
              <listItems.icon />
            </ListItemIcon>
          </Tooltip>

          <ListItemText
            primary={text}
            sx={{
              opacity: isDrawerOpen ? 1 : 0,
              color: theme.palette.primary.main
            }}
          />
          {open ? (
            <RemoveIcon
              onClick={handleClick}
              sx={{
                opacity: isDrawerOpen ? 1 : 0,
                color: theme.palette.primary.main
              }}
            />
          ) : (
            <AddIcon
              onClick={handleClick}
              sx={{
                opacity: isDrawerOpen ? 1 : 0,
                color: theme.palette.primary.main
              }}
            />
          )}
        </ListItemButton>
        <Box display={isDrawerOpen ? 'block' : 'none'} ml={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items
                ?.filter((item, id) => {
                  return ability.can('READ', item?.permission || '');
                })
                .map((item: MenuItemType, key: number) => {
                  return <SingleLevel key={`${item}_${key}`} listItems={item} />;
                })}
            </List>
          </Collapse>
        </Box>
      </ListItem>
    </React.Fragment>
  );
};

export const MenuItems = ({ listItems }: { listItems: MenuItemType }) => {
  if (listItems && listItems.items && listItems.items.length > 0) {
    return <MultiLevel listItems={listItems} />;
  }
  return <SingleLevel listItems={listItems} />;
};
