import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertColor, Snackbar } from '@mui/material';

import {
  closeToastMessage,
  selectToastMessage,
} from 'app/slices/toastMessageSlice';

const Toast = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toastMessage = useSelector(selectToastMessage);

  const dispatch = useDispatch();
  const toastCloseTime = 6000;

  useEffect(() => {
    if (toastMessage.message) {
      setOpen(true);
    }
    const timer = setTimeout(() => {
      setOpen(false);
    }, toastCloseTime);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const handleClose = () => {
    dispatch(closeToastMessage());
  };

  return (
    <>
      {toastMessage?.message ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={toastCloseTime}
          onClose={handleClose}
          key={toastMessage?.key ?? ''}
        >
          <Alert
            onClose={handleClose}
            severity={(toastMessage?.type as AlertColor) ?? 'success'}
            sx={{ width: '100%' }}
          >
            {toastMessage?.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default Toast;
