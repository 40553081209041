import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

type GetAllBannerResponseDTO = {
  serviceCategoryID: number;
  serviceCategoryName: string;
  serviceCategoryDescription?: string;
  serviceCategoryEnabled: 'Y' | 'N';
  bannerImage: string;
  mediaUrl?: string;
};

type BannerState = {
  total: number | null;
  items: GetAllBannerResponseDTO[];
};
const initialState: BannerState = { total: null, items: [] };

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setBannerData: (
      state,
      action: PayloadAction<{ total: number; items: GetAllBannerResponseDTO[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setBannerData } = bannerSlice.actions;

export const selectAllBanners = (state: RootState) => state.banner.items;

export default bannerSlice.reducer;
