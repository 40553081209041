import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TechnicianListItem } from 'app/services/technician';
import { RootState } from 'app/store';

type TechnicianState = {
  total: number | null;
  items: TechnicianListItem[];
};
const initialState: TechnicianState = { total: null, items: [] };

const TechnicianSlice = createSlice({
  name: 'technician',
  initialState,
  reducers: {
    setTechnicianData: (
      state,
      action: PayloadAction<{ total: number; items: any[] }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setTechnicianData } = TechnicianSlice.actions;

export const selectAllTechnicians = (state: RootState) =>
  state.technician.items;

export default TechnicianSlice.reducer;
