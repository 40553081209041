import { ComponentType } from 'react';
import { useLocation, useNavigate, Location } from 'react-router-dom';

interface WithRouterProps {
  location: Location;
  navigate: ReturnType<typeof useNavigate>;
}

export const withRouter = <P extends WithRouterProps>(WrappedComponent: ComponentType<P>) => {
  const Wrapper = (props: Omit<P, keyof WithRouterProps>) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <WrappedComponent {...(props as P)} location={location} navigate={navigate} />;
  };

  return Wrapper;
};

export default withRouter;
