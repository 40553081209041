import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import DrawerHeader from 'layout/DrawerHeader';
import { drawerWidth } from 'utils/constants';
import SidebarMenu from './SidebarMenu';
import DrawerProvider from './drawerProvider';
import LogoImg from '../../assets/images/autoaid-logo.png';

export type MenuItemType = {
  text: string;
  link: string;
  icon: () => JSX.Element;
  type: string;
  permission?: string;
  items?: MenuItemType[];
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function Sidebar({
  openDrawer,
  handleDrawerClose
}: {
  openDrawer: boolean;
  handleDrawerClose: () => void;
}): React.ReactElement {
  const theme = useTheme();

  return (
    <DrawerProvider isDrawerOpen={openDrawer}>
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          <Box display="flex" justifyContent="space-between" alignContent="center">
            {openDrawer && (
              <Box display="flex" justifyContent="space-around" alignContent="center">
                <img alt={'Logo'} src={LogoImg} style={{ maxWidth: '50%' }} />
                {/* <Avatar alt={''} src={''} sx={{width: 40, height: 40, ml: 8, mt: 1, mb: 1}} /> */}
                {/* <Typography
                fontWeight={500}
                fontSize='16px'
                sx={{ alignSelf: 'center' }}
              >
                {'username'}
              </Typography> */}
              </Box>
            )}
            <Box display="flex">
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Box>
          </Box>
        </DrawerHeader>
        <Divider />
        <SidebarMenu />
      </Drawer>
    </DrawerProvider>
  );
}

// export default SideBar;
